import { IBrandBranchData, IPermissionData } from '../../../models/brand';
import { ApiResponse, ICustomerData, ICustomerFormSubmit, ISalesPerson, ISalespersonApiModel } from '../models';
import * as http from './http';
import ApiService from './service';
import { IAddress } from '../../../core/api/models';
import { IStaffPermissionApiModel } from '../../../containers/account-settings/staff-member/userPermission/staff-permission-tab-content';

export class StaffService extends ApiService {
  public async getLanguages(): Promise<any> {
    const url = `${this.apiDomain}/index/listLanguages/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getCountries(): Promise<any> {
    const url = `${this.apiDomain}/user/countries/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getBrandsWithBranches(): Promise<any> {
    const url = `${this.apiDomain}/index/listBrandWithBranches/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getRoleTypes(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/listRolesType/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : userToken);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }


  public async getBrandTypes(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/product/listBrands/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : userToken);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getVinTypes(userToken?: string, brandID?: string, status?: number, vin?: any): Promise<any> {
    const url = `${this.apiDomain}/product/listVin/`;
    const data = new FormData();
    data.append('status', status?.toString())
    data.append('brandID', brandID);
    data.append('userToken', this.userToken ? this.userToken : userToken);
    data.append('multiple', "yes");
    data.append('vinSearch', vin ?? '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getPermissions(
    fields: any,
    userToken?: string
  ): Promise<IPermissionData[]> {
    const url = `${this.apiDomain}/index/listPermissions/`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('userType', fields?.userType);
    if (fields?.roleType) {
      data.append('roleType', fields?.roleType);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async createStaff(fields: any): Promise<any> {
    const url = `${this.apiDomain}/user/createuser`;
    const data = new FormData();
    data.append(
      'userToken',
      this.userToken ? this.userToken : fields?.userToken ?? ''
    );
    data.append('userFirstName', fields?.userFirstName);
    data.append('userLastName', fields?.userLastName);
    data.append('orderPermission', 'true');
    data.append('userPhone', fields?.userPhone);
    if (fields?.userCountryCode) {
      data.append('userCountryCode', fields?.userCountryCode);
    }
    data.append('userPhoneDialCode', fields?.userPhoneDialCode);
    data.append('userEmail', fields?.userEmail);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async editStaff(fields: any): Promise<any> {
    const url = `${this.apiDomain}/user/edituser`;
    const data = new FormData();
    if (fields.userToken) {
      data.append('userToken', fields.userToken);
    }
    data.append('salespersonToken', '');

    data.append('userID', fields?.userID);
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('userFirstName', fields?.userFirstName);
    data.append('userLastName', fields?.userLastName);
    data.append('orderPermission', 'true');
    data.append('userPhone', fields?.userPhone);
    if (fields?.userCountryCode) {
      data.append('userCountryCode', fields?.userCountryCode);
    }
    data.append('userPhoneDialCode', fields?.userPhoneDialCode);
    data.append('userDesignation', fields?.userDesignation);
    data.append('userEmail', fields?.userEmail);
    data.append('countryID', fields?.countryID);
    data.append('languagesKnown', fields?.languagesKnown);
    data.append('userGender', fields?.userGender);
    data.append('userDOB', fields?.userDOB);
    if (fields?.userProfilePicture) {
      data.append('userProfilePicture', fields?.userProfilePicture);
    }
    if (fields?.roleTypeID) {
      data.append('roleTypeID', fields?.roleTypeID);
    }
    if (fields?.brandPermission) {
      data.append('brandPermission', JSON.stringify(fields?.brandPermission));
    }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async addEditSalesPerson(
    fields: ISalespersonApiModel
  ): Promise<ApiResponse<ISalesPerson>> {
    let url = `${this.apiDomain}/salesperson/editsalesperson/`;
    if (!fields.userID) {
      url = `${this.apiDomain}/salesperson/createsalesperson/`;
    }
    const data = new FormData();
    data.append('userToken', '');
    data.append('brandID', '');
    data.append('branchID', '');

    if (fields?.userID) {
      data.append('userID', fields?.userID);
    }
    if (fields.userProfilePicture) {
      data.append('userProfilePicture', fields.userProfilePicture);
    }
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('userFirstName', fields?.userFirstName);
    data.append('userLastName', fields?.userLastName);
    data.append('orderPermission', 'true');
    data.append('userPhone', fields?.userPhone);
    data.append('userPhoneDialCode', fields?.userPhoneDialCode);
    data.append('userEmail', fields?.userEmail);
    data.append('countryID', fields?.countryID);
    data.append('stateID', fields?.stateID);
    data.append('userZip', fields?.userZip);
    data.append('userTypeID', fields?.userTypeID);
    data.append('userAdress1', fields?.userAdress1);
    data.append('assignedStates', fields?.assignedStates + ' ');
    data.append('managerUserIDs', fields?.managerUserIDs + ' ');

    const response = await http.postAsFormData<ApiResponse<ISalesPerson>>(
      url,
      data
    );
    return response?.data;
  }

  public async deteleStaff(staffID: string): Promise<any> {
    const url = `${this.apiDomain}/user/deleteuser`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('userID', staffID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async deteleSalesPerson(
    userID: string
  ): Promise<ApiResponse<ISalesPerson[]>> {
    const url = `${this.apiDomain}/salesperson/listsalespersons/`;
    const data = new FormData();
    data.append('userToken', '');
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('action', 'Delete');
    data.append('userID', userID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async changeStatusOfsalesPerson(
    userID: string,
    status: 'Active' | 'Inactive'
  ): Promise<ApiResponse<ISalesPerson>> {
    const url = `${this.apiDomain}/salesperson/changestatus/`;
    const data = new FormData();
    data.append('userToken', '');
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('status', status);
    data.append('userID', userID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getStaffPermission(
    id?: string,
    userToken?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/user/getStaffPermission`;
    const data = new FormData();
    data.append('subUserID', id || '');
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('brandID', '');
    data.append('branchID', '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async addAddress(address: IAddress): Promise<any> {
    const url = `${this.apiDomain}/user/staffShipping`;
    const data = new FormData();
    data.append('shippingFname', address.shippingFname);
    data.append('shippingAddress1', address.shippingAddress1);
    data.append('shippingCountryID', address.shippingCountryID);
    data.append('shippingStateID', address.shippingStateID);
    data.append('shippingCity', address.shippingCity);
    data.append('shippingZip', address.shippingZip);
    data.append('shippingPhoneDialCode', address.shippingPhoneDialCode);
    data.append('shippingPhone', address.shippingPhone);
    data.append('staffUserID', address.staffUserID);
    if (address.warehouseIDs) {
      data.append(
        'warehouseIDs',
        JSON.stringify(address.warehouseIDs.toString())
      );
    }
    data.append('shippingPhoneCountryCode', address.shippingPhoneCountryCode);
    data.append('action', address.action);
    if (address.userToken) {
      data.append('userToken', address.userToken);
    }

    data.append('salespersonToken', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async editAddress(address: IAddress): Promise<any> {
    const url = `${this.apiDomain}/user/staffShipping/`;
    const data = new FormData();

    data.append('userShippingID', address?.userShippingID);
    data.append('shippingFname', address?.shippingFname);
    data.append('shippingAddress1', address?.shippingAddress1);
    data.append('shippingCountryID', address?.shippingCountryID);
    data.append('shippingStateID', address?.shippingStateID);
    data.append('shippingCity', address?.shippingCity);
    data.append('shippingZip', address?.shippingZip);
    data.append('shippingPhoneDialCode', address?.shippingPhoneDialCode);
    data.append('shippingPhone', address?.shippingPhone);
    data.append('staffUserID', address?.staffUserID);
    // data.append('warehouseIDs', JSON.stringify(address?.warehouseIDs?.toString()));
    data.append('shippingPhoneCountryCode', address?.shippingPhoneCountryCode);
    data.append('action', address?.action);
    if (address.userToken) {
      data.append('userToken', address.userToken);
    }

    data.append('salespersonToken', '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async editUserPermission(
    modelData: IStaffPermissionApiModel,
    userToken?: string
  ): Promise<any> {
    const url = `${this?.apiDomain}/user/addStaffPermission`;
    const data = new FormData();
    data.append('action', modelData.action);
    data.append('subUserID', modelData?.staffUserID);
    data.append('roleIDs', modelData?.generalPermissions);
    data.append(
      'brandPermission',
      JSON?.stringify(modelData?.brandPermissions)
    );
    data.append('roleTypeID', modelData?.roleType?.roleTypeID);
    data.append(
      'userToken',
      this?.userToken ? this?.userToken : userToken ?? ''
    );

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  // get FAQ
  public async getFaq(): Promise<any> {
    const url = `${this.apiDomain}/faq/getFaqList/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async createCustomer(fields: ICustomerData): Promise<any> {
    const url = `${this.apiDomain}/product/updateCustomer`;
    const data = new FormData();
    data.append(
      'userToken',
      this.userToken ? this.userToken : fields?.userToken ?? ''
    );
    data.append('customerFirstName', fields?.firstName);
    data.append('customerMiddleName', fields?.middleName);
    data.append('customerLastName', fields?.lastName);
    data.append('hidVin', fields?.vin);
    data.append('vinRegNumber', fields?.vehicleRegNumber);
    data.append('customerMobileNumber', fields?.mobileNumber?.entered);
    data.append('userCountryCode', fields?.userCountryCode);
    data.append('userPhoneDialCode', fields?.userPhoneDialCode);
    data.append('customerEmail', fields?.email);
    data.append('countryID', fields?.countryID);
    data.append('customerInvoiceDate', fields?.invoiceDate);
    data.append('customerInvoiceNumber', fields?.invoiceNumber);
    data.append('vinRegNumber', fields?.vehicleRegNumber);
    data.append('variant', fields?.variant);
    data.append('customerCity', fields?.city);
    data.append('fileName', fields?.istemara);
    data.append('invoiceFile', fields?.invoice);
    data.append('customerStreet', fields?.street);
    data.append('customerPOBox', fields?.poBox);
    data.append('brand', fields?.brand?.brandId);
    data.append('dealerName', fields?.dealerName);

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

}
