import React, { useState } from 'react';

import CopyClipboard from '../../../components/copy-to-clipboard';
import '../../../containers/search-tab/search-box.scss';
import { CartItem } from '../../../models/order-models';
import { CartCounter } from './cart-counter';
import { useCart } from '../../../core/hooks';
import { useConfirmationDialog } from '../../../components/confirm-popup/confirm-popup';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../../utils/text-utils';
import Currency from 'react-currency-formatter';
import { CurrencyFormatter } from '../../../components/currency-formatter';

export const CartItemComponent = (props: {
  item: CartItem;
  cartId: string;
  isViewOnly: boolean;
  onSelectionChange?: () => void;
  isChecked?: boolean;
  canEditCart?: boolean;
  cartUserStatus?: string;
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const cartID = props.cartId;
  const cartItem = props.item;
  const [loading, setLoading] = useState(false);
  const { removeFromCart } = useCart();
  const removeItem = async () => {
    const granded = await getConfirmation({
      title: translate('common.areYouSureToDeleteThisItem'),
    });
    if (granded) {
      try {
        setLoading(true);
        await removeFromCart(cartItem, cartID);
      } catch (err) {
        toastr.error('Error', 'Somethign went wrong');
      } finally {
        setLoading(false);
      }
    }
  };

  const getAvailabilityText = () => {
    let text = '';
    text += `${translate('orders.immediatelyAvailable')}: ${cartItem.branchStockQty
      }  `;
    text += ' / ';
    text += `${translate('orders.availableInDays')}: ${cartItem.centralStockQty
      }`;
    return text.trim();
  };
  return (
    <div>
      <div className="cartItem pl-lg-4 pr-xl-4 px-3 py-2 border-bottom d-block">
        <div className="position-relative py-1 pr-lg-0 pr-4">
          {/* <div
            className="cartItem__remove position-absolute cursor-pointer font-md zIndex-9"
            onClick={() => {
              removeItem();
            }}
          >
            <i className="icon-delete"></i>
          </div> */}
          <div className="row">
            <div className="col-sm col-12 py-1">
              <div
                onClick={() => {
                  if (
                    !props?.canEditCart &&
                    props?.cartUserStatus !== 'Inactive'
                  ) {
                    props.onSelectionChange?.();
                  }
                }}
                className="font-sm text-gray-900 font-weight-medium py-1 cursor-pointer"
              >
                {!props?.canEditCart && (
                  <input
                    type="checkbox"
                    checked={props.isChecked}
                    id={cartItem.cartItemID}
                  />
                )}
                <label>
                  {cartItem?.itemDetails?.productTitle ??
                    cartItem?.productTitle}
                </label>
              </div>
              <div className="font-xs text-gray-600 w-100 px-4">
                <CopyClipboard value={cartItem.optionSKU}>
                  <div className="copy copy--sm d-inline-block cursor-pointer">
                    {cartItem.optionSKU}{' '}
                    <i className="icon-copy ml-1 font-xxxs"></i>
                  </div>
                </CopyClipboard>
              </div>
                            
              <div className='px-4 py-1' style={{ display: 'flex', alignItems: 'center' }}>
                {cartItem?.fdNumber &&
                  <div className="font-md" style={{ marginRight: '10px' }}>
                    FD : <span className='text-gray-600'>{cartItem?.fdNumber}</span>
                  </div>
                }
                {cartItem?.mcNumber &&
                  <div className="font-md">
                    MC : <span className='text-gray-600'>{cartItem?.mcNumber}</span>
                  </div>
                }
              </div>
            </div>
            <div className="col-auto py-1">
              <div className="cartItem__action-block">
                {!props.isViewOnly && (
                  <div className="tooltip position-relative sm">
                    <CartCounter
                      cartItem={cartItem}
                      cartUserStatus={props?.cartUserStatus}
                    ></CartCounter>
                    {props?.cartUserStatus === 'Inactive' &&
                      <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                        <p className="font-weight-medium my-1 text-gray-900 font-sm">
                          {translate('common.inactive_user_message')}
                        </p>
                      </span>
                    }
                  </div>
                )}
                {props.isViewOnly && (
                  <h6>{cartItem?.orderItemQuantity ?? 0}</h6>
                )}
              </div>
              <div className="font-xxs text-gray-600 w-100 pt-1">
                <CurrencyFormatter
                  quantity={
                    cartItem?.offerPrice?.toNumber() ??
                    cartItem?.itemDetails?.unitPrice ??
                    cartItem?.orderItemPrice?.toNumber() ??
                    0
                  }
                />{' '}
                {translate('cart.EACH')}
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 col-sm-3 col font-md text-gray-900 font-weight-medium text-right py-1">
              <CurrencyFormatter quantity={cartItem?.orderItemSubtotal} />{' '}
              {cartItem?.savedPriceVal?.toNumber() > 0 &&
                cartItem?.customerPrice?.toNumber() === 0 && (
                  <div className="font-xs text-gray-600 w-100">
                    {translate('cart.savedSAR')} {cartItem?.savedPriceVal}
                  </div>
                )}
              {cartItem?.customerPrice?.toNumber() > 0 && (
                <div>
                  <del className="font-xs text-gray-600 font-weight-normal">
                    <CurrencyFormatter
                      quantity={
                        Number(cartItem?.actualPrice) *
                        cartItem.orderItemQuantity
                      }
                    />{' '}
                  </del>
                </div>
              )}
            </div>
          </div>
          {getAvailabilityText() !== '' && (
            <div className="font-xs text-gray-600 w-100 mt-2">
              <div className="bg-body rounded px-lg-3 px-2 py-1 line-height-normal d-inline-block">
                {getAvailabilityText()}
              </div>
            </div>
          )}
        </div>
      </div>

      {loading && (
        <div className="numberField__loading">
          <img
            src={require('../../../components/counter/loading.gif')}
            alt="loading..."
          />
        </div>
      )}
    </div>
  );
};
