import { FilterTypes } from "../../../containers/vehicles/components/order-filter-component";
import { BuisnessDetailData, ConversionCustomerOrderDetail, ConversionRateData, ConversionRateReportData, ConversionRateSalesDetail, CustomerGroup, CustomerGroupDetail, IBrandWiseTotal, ISalesManReport, ISalesPerformanceReport, SKUReportData, StatusQuotaionDetail, StatusReport, SupervisorFilter, TopSalesCustomerData, TopSalesPersonData } from "../models/report";
import { ApiResponse } from "../models";
import ApiService from "./service"
import * as http from './http';
import { da } from "date-fns/locale";


export class ReportSevice extends ApiService {

  //Sales Performance report 

  public async getSalesPerformanceReport(params: any): Promise<ISalesManReport> {
    const url = `${this.apiDomain}/report/salePerformance/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    data.append('branchID', '' );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<ISalesManReport>>(url, data);
    return response?.data?.result;
  }

  public async getSalesPerformanceReportDuplicate(params: any): Promise<ApiResponse<ISalesManReport[]>> {
    const url = `${this.apiDomain}/report/salePerformance/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    data.append('branchID', '' );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<ISalesManReport[]>>(url, data);
    return response?.data;
  }


  //top sales person report

  public async getTopSalesPersonReport(
    params: any
  ): Promise<ApiResponse<TopSalesPersonData[]>> {
    const url = `${this.apiDomain}/report/topSellingSalesperson/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<TopSalesPersonData[]>>(
      url,
      data
    );
    return response?.data;
  }

  //top sale customer report
  public async getTopSalesCustomerReport(
    params: any
  ): Promise<ApiResponse<TopSalesCustomerData[]>> {
    const url = `${this.apiDomain}/report/topSellingCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<TopSalesCustomerData[]>>(
      url,
      data
    );
    return response?.data;
  }

  //sku report
  public async getSKUReport(
    params: any
  ): Promise<ApiResponse<SKUReportData[]>> {
    const url = `${this.apiDomain}/report/skuReport/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    data.append('branchID', '' );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<SKUReportData[]>>(
      url,
      data
    );
    return response?.data;
  }

  //conversion rate report
  public async getConversionRateReport(
    params: any
  ): Promise<ConversionRateReportData[]> {
    const url = `${this.apiDomain}/report/conversionRates/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.supervisor) data.append('supervisor', params.supervisor);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  // conversion rate - sales amount
  public async getConversionRateSalesReport(
    params: any
  ): Promise<ConversionRateReportData[]> {
    const url = `${this.apiDomain}/report/conversionRatesSaleAmount/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.supervisor) data.append('supervisor', params.supervisor);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  // download conversion-rate-sale
  public async getConversionRateSalesReportDownload(
    params: any
  ): Promise<ApiResponse<ConversionRateReportData[]>> {
    const url = `${this.apiDomain}/report/conversionRatesSaleAmount/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.supervisor) data.append('supervisor', params.supervisor);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data;
  }

  //conversion rate duplicate

  public async getConversionRateReportDuplicate(
    params: any
  ): Promise<ApiResponse<ConversionRateReportData[]>> {
    const url = `${this.apiDomain}/report/conversionRates/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data;
  }

  //conversion rate customer detail report
  public async getConversionRateDetailReport(
    params: any
  ): Promise<ConversionRateReportData[]> {
    const url = `${this.apiDomain}/report/conversionRatesCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  // conversion-sale-detail
  public async getConversionRateSaleDetailReport(
    params: any
  ): Promise<ConversionRateReportData[]> {
    const url = `${this.apiDomain}/report/conversionRatesSaleAmountCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data?.result;
  }


  //conversion rate customer order detail report
  public async getConversionCustomerOrderDetail(
    params: any
  ): Promise<ApiResponse<ConversionCustomerOrderDetail>> {
    const url = `${this.apiDomain}/report/conversionRatesCustomerOrder/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    data.append('specificDate', params.specificDate );
    data.append('userID', params.userID );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionCustomerOrderDetail>>(
      url,
      data
    );
    return response?.data;
  }

  // customer sale order conversion detail
  public async getConversionCustomerSaleOrderDetail(
    params: any
  ): Promise<ApiResponse<ConversionCustomerOrderDetail>> {
    const url = `${this.apiDomain}/report/conversionRatesSaleAmountCustomerOrder/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    data.append('specificDate', params.specificDate );
    data.append('userID', params.userID );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionCustomerOrderDetail>>(
      url,
      data
    );
    return response?.data;
  }

  // status-customer-detail-order
  public async getStatusCustomerOrderDetail(
    params: any
  ): Promise<ApiResponse<ConversionCustomerOrderDetail>> {
    const url = `${this.apiDomain}/report/orderStatusOrderDetails/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    data.append('specificDate', params.specificDate );
    data.append('userID', params.userID );
    data.append('OrdStatus',params?.OrdStatus)
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionCustomerOrderDetail>>(
      url,
      data
    );
    return response?.data;
  }

  //conversion sales person detail
  public async getConversionSalesDetail(
    params: any
  ): Promise<ApiResponse<ConversionRateSalesDetail[]>> {
    const url = `${this.apiDomain}/report/conversionRatesCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateSalesDetail[]>>(
      url,
      data
    );
    return response?.data;
  }

  //status report
  public async getSalesStatusReport(
    params: any
  ): Promise<StatusReport[]> {
    const url = `${this.apiDomain}/report/orderStatus/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.paymentMethod) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((pay) => {
            if (pay.value) val.push(pay.value);
            return;
          });
          if (val.length > 0) {
            data.append('paymentMethod', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<StatusReport[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getStatusReportDuplicate(
    params: any
  ): Promise<ApiResponse<StatusReport[]>> {
    const url = `${this.apiDomain}/report/orderStatus/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.paymentMethod) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((pay) => {
            if (pay.value) val.push(pay.value);
            return;
          });
          if (val.length > 0) {
            data.append('paymentMethod', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<StatusReport[]>>(
      url,
      data
    );
    return response?.data;
  }

  // status detail report
  public async getStatusDetailReport(
    params: any
  ): Promise<ConversionRateReportData[]> {
    const url = `${this.apiDomain}/report/orderStatusCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.paymentMethod) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((pay) => {
            if (pay.value) val.push(pay.value);
            return;
          });
          if (val.length > 0) {
            data.append('paymentMethod', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<ConversionRateReportData[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  // status customer order details
  public async getStatusOrderDetailReport(
    params: any
  ): Promise<ApiResponse<StatusQuotaionDetail>> {
    const url = `${this.apiDomain}/report/orderStatusQuotation/`;
    const data = new FormData();
    if (params?.userID) data.append('userID', params.userID);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<StatusQuotaionDetail>>(
      url,
      data
    );
    return response?.data;
  }

  // customer group
  public async getCustomerGroupReport(
    params: any
  ): Promise<CustomerGroup[]> {
    const url = `${this.apiDomain}/report/customerGroup/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.paymentMethod) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((pay) => {
            if (pay.value) val.push(pay.value);
            return;
          });
          if (val.length > 0) {
            data.append('paymentMethod', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
        if (p === FilterTypes.userGroup) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('userGroupID', value);
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<CustomerGroup[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getCustomerGroupReportDuplicate(
    params: any
  ): Promise<ApiResponse<CustomerGroup[]>> {
    const url = `${this.apiDomain}/report/customerGroup/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.multiSelectSalesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.paymentMethod) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((pay) => {
            if (pay.value) val.push(pay.value);
            return;
          });
          if (val.length > 0) {
            data.append('paymentMethod', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
        if (p === FilterTypes.userGroup) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('userGroupID', value);
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<CustomerGroup[]>>(
      url,
      data
    );
    return response?.data;
  }

  // customer buisness type detail
  public async getCustomerGroupDetailReport(
    params: any
  ): Promise<CustomerGroupDetail[]> {
    const url = `${this.apiDomain}/report/customerGroupBusinessType/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.paymentMethod) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((pay) => {
            if (pay.value) val.push(pay.value);
            return;
          });
          if (val.length > 0) {
            data.append('paymentMethod', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<CustomerGroupDetail[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  //filter supervisor
  public async getSupervisor(): Promise<SupervisorFilter[]> {
    const url = `${this.apiDomain}/report/getSupervisorFilter/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<SupervisorFilter[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

}