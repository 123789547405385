import React, { useEffect, useState } from 'react';
import empty from '../../containers/home/cart/empty.png';
import { translate } from '../../utils/text-utils';
import { ContainerLoader } from '../loader';
import moment from 'moment';
import { decodeHtmlEntities } from '../../utils/common';

export const AppTable = (props: {
  data: any[];
  columns: IColumn[];
  onRowClick?: (data: any) => void;
  onViewClick?: (data: any) => void;
  onEditClick?: (data: any) => void;
  multiSelectionConfig?: IMultiSelectionConfig;
  isLoading?: boolean;
  showEmptyResult?: boolean;
}) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  useEffect(() => {
    if (
      props.multiSelectionConfig &&
      props.multiSelectionConfig?.defaultSelections
    ) {
      setSelectedItems(props.multiSelectionConfig?.defaultSelections);
    }
  }, []);
  useEffect(() => {
    props.multiSelectionConfig?.onChange?.(selectedItems);
  }, [selectedItems]);

  return (
    <>
      <div className="table-layout-outer bg-white border-radius-lg sm-table">
        <table className="w-100">
          <thead>
            <tr>
              <th>Branch</th>
              <th>Salesman</th>
              <th>Distributor Name</th>
              <th>Dealer CR</th>
              <th>Brand</th>
              <th>VIN</th>
              <th>Model Year</th>
              <th>Model</th>
              <th>variant</th>
              <th>Description</th>
              <th>AJVA Invoice Number</th>
              <th>AJVA Sold Date</th>
              <th>AJVA Delivery Date</th>
              <th>Customer</th>
              <th>Customer Invoice Date</th>
              <th>Action</th>
            </tr>
          </thead>
          {!props?.isLoading &&
            <tbody>
              {props?.data?.map(item => {

                const currentDate = moment();
                const thirtyDaysAgo = moment().subtract(30, 'days');
                const isDateGreaterThan30Days = moment(item?.customerDetailsAddedOn).diff(thirtyDaysAgo, 'days') >= 0;

                return (
                  <tr
                  >
                    <td>{item?.branchName}</td>
                    <td>{item?.salesMan}</td>
                    <td>{decodeHtmlEntities(item?.distributor)}</td>
                    <td>{item?.cr}</td>
                    <td>{item?.brandname}</td>
                    <td>{item?.vinNumber}</td>
                    <td>{item?.makeyear}</td>
                    <td>{item?.model}</td>
                    <td>{item?.variant}</td>
                    <td>{item?.description}</td>
                    <td>{item?.ajvaInvoiceNumber}</td>
                    <td>{item?.ajvaSoldDate !== "0000-00-00" ? moment(item?.ajvaSoldDate)?.format('DD-MM-YYYY') : ""}</td>
                    <td>{item?.ajvaDelierydate !== "0000-00-00" ? item?.ajvaDelierydate : ""}</td>
                    {item?.customerFirstName &&
                      <td>{item?.customerFirstName + " " + item?.customerMiddleName + " " + item?.customerLastName}</td>}
                    {!item?.customerFirstName &&
                      <td>
                        <button className="bg-transparent border-0 text-primary font-sm font-weight-semibold px-0  mr-2" onClick={() =>
                          props.onEditClick({
                            item: item,
                          })
                        }>
                          <svg
                            width="13px"
                            height="13px"
                            viewBox="0 0 32 32"
                            version="1.1"
                            className='align-text-top mr-1'
                          >
                            <g stroke="none" strokeWidth="1" fill="none">
                              <g

                                transform="translate(-464.000000, -1087.000000)"
                                fill="#023F88"
                              >
                                <path
                                  d="M480,1117 C472.268,1117 466,1110.73 466,1103 C466,1095.27 472.268,1089 480,1089 C487.732,1089 494,1095.27 494,1103 C494,1110.73 487.732,1117 480,1117 L480,1117 Z M480,1087 C471.163,1087 464,1094.16 464,1103 C464,1111.84 471.163,1119 480,1119 C488.837,1119 496,1111.84 496,1103 C496,1094.16 488.837,1087 480,1087 L480,1087 Z M486,1102 L481,1102 L481,1097 C481,1096.45 480.553,1096 480,1096 C479.447,1096 479,1096.45 479,1097 L479,1102 L474,1102 C473.447,1102 473,1102.45 473,1103 C473,1103.55 473.447,1104 474,1104 L479,1104 L479,1109 C479,1109.55 479.447,1110 480,1110 C480.553,1110 481,1109.55 481,1109 L481,1104 L486,1104 C486.553,1104 487,1103.55 487,1103 C487,1102.45 486.553,1102 486,1102 L486,1102 Z"
                                ></path>
                              </g>
                            </g>
                          </svg>{' '}
                          Add Cutomer
                        </button>
                      </td>}
                    <td>{item?.customerInvoiceDate !== "0000-00-00" ? moment(item?.customerInvoiceDate)?.format('DD-MM-YYYY') : ""}</td>
                    <td>
                      <button className="bg-transparent border-0 text-primary font-sm font-weight-semibold px-0 mr-2"
                        onClick={() =>
                          props.onViewClick({
                            vinID: item?.vinID,
                          })
                        }>
                        View
                      </button>
                      {item?.customerFirstName && isDateGreaterThan30Days &&
                        <>
                          <button className="bg-transparent border-0 text-primary font-sm font-weight-semibold px-0  mr-2" onClick={() =>
                            props.onEditClick({
                              item: item,
                            })
                          }>
                            Edit
                          </button>
                        </>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>}
        </table>
      </div >
      {props?.isLoading && (
        <div className="py-5">
          <ContainerLoader />
        </div>
      )
      }
      {
        props.data.length === 0 && !!!props.isLoading && props.showEmptyResult && (
          <div>
            <div className="cartBlock__item py-5">
              <div className="cartBlock__itemHead">
                {translate('orders.No_Result')}
              </div>
              <div className="cartBlock__img mt-3">
                <img src={empty} alt="" />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export interface IColumn {
  key: string;
  title: string;
  headerRenderer?: (hederText: string) => JSX.Element;
  columnRenderer?: (data: any, index: number) => JSX.Element;
  isSalesPerson?: boolean;
}

export interface IMultiSelectionConfig {
  key: string;
  defaultSelections?: string[];
  onChange?: (selectedValues: string[]) => void;
}
