import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/button';
import { UserPermissionTypes } from '../../../../core/api/models';
import { useAuth } from '../../../../core/hooks';
import { translate } from '../../../../utils/text-utils';
import { TabFilters } from '../../../vehicles/vehicle-listing-screen';
import { RouteKeys } from '../../../routes/route-keys';

export const ConfirmSummary = (props: {
  header: string;
  text: string;
  buttonTitle?: string;
  redirectionRoute?: string;
}) => {
  const history = useHistory();
  const { hasRole } = useAuth();

  return (
    <div className="text-center bg-white confirm-msg d-flex flex-column align-items-center justify-content-center py-5">
      <img
        src={require('../../../../assets/images/confirm-img.png')}
        className="img-fluid mb-4 pb-3"
      />
      <h6 className="font-weight-bold mb-2">{props.header}</h6>
      <div className="font-xl text-gray-600 col-md-6 col-12 px-0">
        {props.text}
      </div>

      <div className="row m-0 mt-4 flex-column align-items-center pt-2 w-100">
        <div className="col-lg-2 col-md-4 col-sm-7 col-12 mb-2">
          <Button
            size="md"
            className=" Button__primary Button--radius Button--block"
            onClick={() => {
              history.push(RouteKeys.Home);
            }}
          >
            {translate('cart.backHome')}
          </Button>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-7 col-12">
          {hasRole(UserPermissionTypes.customer_orders) && (
            <Button
              size="md"
              className=" Button__primary-outline Button--radius Button--block"
              onClick={() => {
                history.push(
                  props.redirectionRoute ??
                  `${RouteKeys.Orders}/`
                );
              }}
            >
              {props.buttonTitle ?? translate('orders.viewMyOrders')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
