import { FilterTypes } from '../../../containers/vehicles/components/order-filter-component';
import {
  IDeliveryAddress,
  IOrderListParams,
} from '../../../models/order-models';

import {
  ICreditApplyParams,
  ICreditDetails,
  ICreditRequestResult,
  ICreditTerm,
  IDueCustomersResponse,
  IDuePaymentListResult,
} from '../models/payment-models';

import {
  ApiResponse,
  IPaymentHistoryFilterParams,
  ICustomerFilterParams,
  IPaymentHistory,
  UserType,
} from '../models';
import {
  IDuePaymentDetails,
  IProcessArbPaymentRequest,
  IProcessPaymentRequest,
} from '../../../models/payment-models';
import * as http from './http';
import ApiService from './service';
import api from '../../../api';

export class PaymentService extends ApiService {
  public async getDuePayments(
    params?: IOrderListParams,
    userToken?: string
  ): Promise<IDuePaymentListResult> {
    const url = `${this.apiDomain}/payment/paymentdueorders/`;

    const data = new FormData();
    if (params?.requestID) data.append('requestID', params.requestID);
    if (params?.startDate) data.append('fromDate', params.startDate);
    if (params?.endDate) data.append('toDate', params.endDate);
    if (params?.requestedBy) data.append('requestedBy', params.requestedBy);
    if (params?.approvedBy) data.append('approvalUser', params.approvedBy);
    if (params?.export) data.append('export', 'Yes');
    data.append('brandID', params.brand ?? '');
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('branchID', params.branchIDs ?? '');
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    const response = await http.postAsFormData<
      ApiResponse<IDuePaymentListResult>
    >(url, data);

    return response?.data?.result;
  }

  public async getPaymentHistory(
    filterParams: IPaymentHistoryFilterParams
  ): Promise<ApiResponse<IDuePaymentListResult>> {
    const url = `${this.apiDomain}/payment/paymenthistory/`;
    const data = new FormData();
    data.append('page', `${filterParams.page}`);
    data.append('brandID', '');
    data.append('branchID', '');
    if (filterParams.searchName) {
      data.append('searchName', filterParams?.searchName ?? '');
    }
    if (filterParams.fromDate) {
      data.append('fromDate', filterParams.fromDate);
      data.append('toDate', filterParams.toDate);
    }

    Object.keys(filterParams.filter)?.forEach((p) => {
      if (p === FilterTypes.brand) {
        const value = (filterParams.filter as any)[p]?.value;
        data.append('brandID', value);
      }

      if (p === FilterTypes.branch) {
        let values = (filterParams.filter as any)[p];
        values = values.map((item) => item.value);
        data.append('branchID', values);
      }
    });

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data;
  }

  public async getPaymentHistoryDetails(
    id: string
  ): Promise<ApiResponse<IDuePaymentListResult>> {
    const url = `${this.apiDomain}/payment/paymenthistory/`;
    const data = new FormData();

    data.append('brandID', '');
    data.append('branchID', '');
    data.append('paymentOrderID', id);

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result?.list;
  }

  public async processPayment(
    params?: IProcessPaymentRequest
  ): Promise<IDuePaymentListResult> {
    const url = `${this.apiDomain}/payment/paymentdueorders/`;

    const data = new FormData();
    data.append('action', params.action);
    if (params.userToken != null) {
      data.append('userToken', params.userToken);
    }
    data.append('masterOrderID', params.masterOrderID);
    data.append('userGivenAmount', params.userGivenAmount);
    data.append(
      'paymentSettingsGroupKeySelected',
      params.paymentSettingsGroupKeySelected
    );
    data.append('bankName', params.bankName);
    data.append('brandID', '');
    data.append('branchID', '');

    if (params?.purchaseFile) {
      data.append('purchaseFile', params.purchaseFile);
    }
    if (params?.transactionDetails) {
      data.append('transactionDetails', params.transactionDetails);
    }
    if (params?.chequeNumber) {
      data.append('chequeNumber', params.chequeNumber);
    }
    if (params?.chequeDate) {
      data.append('chequeDate', params.chequeDate);
    }

    const response = await http.postAsFormData<
      ApiResponse<IDuePaymentListResult>
    >(url, data);

    return response?.data?.result;
  }

  // arb due payment
  public async processArbDuePayment(
    params?: IProcessArbPaymentRequest
  ): Promise<any> {
    const url = `${this.apiDomain}/payment/paymentdueorders/`;

    const data = new FormData();
    data.append('action', params.action);
    if (params.userToken != null) {
      data.append('userToken', params.userToken);
    }
    data.append('masterOrderID', params.masterOrderID);
    data.append('userGivenAmount', params.userGivenAmount);
    data.append(
      'paymentSettingsGroupKeySelected',
      params.paymentSettingsGroupKeySelected
    );
    data.append('brandID', '');
    data.append('branchID', '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data;
  }

  // verify due-payment
  public async getArbVerifyDuePay(
    paymentOrderID: string
    // brandID: string,
    // branchID: string,
    // userToken?: string
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/payment/verifyArabPaymentDueMobile/`;
    const data = new FormData();
    data.append('paymentOrderID', paymentOrderID);
    // data.append('brandID', brandID);
    // data.append('branchID', branchID);
    // if (userToken) {
    //   data.append('userToken', userToken);
    // }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getCreditRequests(
    params: any
  ): Promise<ApiResponse<ICreditRequestResult>> {
    const url = `${this.apiDomain}/payment/creditrequestlist/`;
    const data = new FormData();
    data.append('status', params.status);
    data.append('searchRequestID', params.searchRequestID);
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    Object.keys(params.filter)?.forEach((p) => {
      if (p === FilterTypes.brand) {
        const value = (params.filter as any)[p]?.value;
        if (value) {
          data.append('brandID', value);
        }
      }
      if (p === FilterTypes.branch) {
        const val: string[] = [];
        (params.filter as any)[p]?.map((brnch) => {
          if (brnch.value) val.push(brnch.value);
          return;
        });
        if (val.length > 0) {
          data.append('branchID', val.toString());
        }
      }
    });

    const response = await http.postAsFormData<
      ApiResponse<ICreditRequestResult>
    >(url, data);
    return response.data;
  }

  public async getPaymentDueUsersList(
    filterParams: ICustomerFilterParams
  ): Promise<ApiResponse<IDueCustomersResponse>> {
    const url = `${this.apiDomain}/payment/paymentdueuserslist/`;
    const data = new FormData();
    data.append('page', `${filterParams.page}`);
    data.append('brandID', '');
    data.append('branchID', '');
    if (filterParams.searchKeyword) {
      data.append('searchKeyword', filterParams?.searchKeyword ?? '');
    }
    if (filterParams.fromDate) {
      data.append('fromDate', filterParams.fromDate);
    }
    if (filterParams.toDate) {
      data.append('toDate', filterParams.toDate);
    }
    Object.keys(filterParams.filter)?.forEach((p) => {
      if (p === FilterTypes.brand) {
        const value = (filterParams.filter as any)[p]?.value;
        if (value) {
          data.append('brandID', value);
        }
      }
      if (p === FilterTypes.branch) {
        const val: string = (filterParams.filter as any)[p]
          ?.map((brnch) => brnch.value)
          ?.join(',');
        if (val.length > 0) {
          data.append('branchID', val);
        }
      }
    });

    const response = await http.postAsFormData<
      ApiResponse<IDueCustomersResponse>
    >(url, data);
    return response.data;
  }

  public async applyCredit(params?: ICreditApplyParams): Promise<ApiResponse> {
    const url = `${this.apiDomain}/payment/newcreditrequest/`;
    const data = new FormData();
    if (params.userToken) {
      data.append('userToken', params.userToken);
    }
    if (params.requestID) {
      data.append('requestID', params.requestID);
    }

    data.append('creditAmountRequested', params.creditAmountRequested);
    data.append('creditTermID', params.creditTerm?.credittermID);
    data.append('creditTermRequested', params.creditTerm?.credittermName);
    data.append('addressID', params.addressID);
    data.append('businessAnalysisDetails', params.businessAnalysisDetails);
    data.append('branchSummary', params.branchSummary);
    data.append('summaryDetails', params.summaryDetails);
    data.append('bankFacilities', params.bankFacilities);
    data.append('authorizedPersonDetails', params.authorizedPersonDetails);
    data.append('CustomerLetter', params.CustomerLetter);
    data.append('SIMAH', params.SIMAH);
    data.append('CRC', params.CRC);
    data.append('COC', params.COC);
    data.append('VAT', params.VAT);
    data.append('AFS', params.AFS);
    data.append('BS', params.BS);
    data.append('SS', params.SS);
    data.append('BA', params.BA);
    data.append('brandID', '');
    data.append('branchID', '');

    const response = await http.postAsFormData<ApiResponse>(url, data);

    return response.data;
  }

  public async getCreditTerms(): Promise<ICreditTerm[]> {
    const url = `${this.apiDomain}/payment/creditTermList/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<ICreditTerm[]>>(
      url,
      data
    );
    return response?.data?.result;
  }
  public async getPaymentHistoryDuplicate(
    params: IOrderListParams
  ): Promise<ApiResponse<IPaymentHistory[]>> {
    const url = `${this.apiDomain}/payment/paymenthistory/`;
    const data = new FormData();
    data.append('status', params?.status);
    if (params?.requestID) data.append('requestID', params.requestID);
    if (params?.startDate) data.append('fromDate', params.startDate);
    if (params?.endDate) data.append('toDate', params.endDate);
    if (params?.requestedBy) data.append('requestedBy', params.requestedBy);
    if (params?.approvedBy) data.append('approvalUser', params.approvedBy);
    if (params?.export) data.append('export', 'Yes');
    data.append('brandID', params.brand ?? '');
    data.append('branchID', params.branchIDs ?? '');
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    if (api.getUserType() === UserType.Salesperson) {
      data.append('userToken', '');
    }
    const response = await http.postAsFormData<ApiResponse<IPaymentHistory[]>>(
      url,
      data
    );
    if (!params?.status) {
      response.data.result = [];
    }
    return response?.data;
  }

  public async getCreditAddress(
    userToken?: string
  ): Promise<IDeliveryAddress[]> {
    const url = `${this.apiDomain}/payment/listaddress/`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    // data.append('salespersonToken', '');
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<IDeliveryAddress[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getCreditDetails(
    requestID: string,
    action?: string,
    userToken?: string
  ): Promise<ApiResponse<ICreditDetails>> {
    const url = `${this.apiDomain}/payment/creditrequestlist`;

    const data = new FormData();
    data.append('requestID', requestID);
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (action) {
      data.append('action', action);
    }
    // else {
    //   data.append('details', 'Yes');
    // }

    const response = await http.postAsFormData<ApiResponse<ICreditDetails>>(
      url,
      data
    );

    return response?.data;
  }

  public async getDuePaymentDetails(
    masterOrderId: string,
    userToken?: string
  ): Promise<IDuePaymentDetails> {
    const url = `${this.apiDomain}/payment/paymentdueorderdetails/`;

    const data = new FormData();
    data.append('masterOrderID', masterOrderId);
    if (userToken) {
      data.append('userToken', userToken);
    }

    data.append('brandID', '');
    data.append('branchID', '');

    const response = await http.postAsFormData<ApiResponse<IDuePaymentDetails>>(
      url,
      data
    );

    return response?.data?.result;
  }

  public async downloadPaymentDetails(
    masterOrderId: string,
    userToken?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/payment/printReciept`;

    const data = new FormData();
    data.append('paymentDetailOrderID', masterOrderId);
    if (userToken) {
      data.append('userToken', userToken);
    }

    data.append('brandID', '');
    data.append('branchID', '');

    const response = await http.postAsFormData<ApiResponse<IDuePaymentDetails>>(
      url,
      data
    );

    return response?.data;
  }
}
