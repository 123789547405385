import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  ArabicLocale,
  cartResetAction,
  changeLanguageAction,
  EnglishLocale,
  ILocale,
  logoutAction,
  State,
} from '../../core/store';
import { RouteKeys } from '../routes/route-keys';
import { UserType, UserPermissionTypes } from '../../core/api/models';
import noImg from '../../assets/images/no-company.png';
import './SideMenu.scss';
import { useAuth } from '../../core/hooks';
import { translate } from '../../utils/text-utils';
import { useNotifications } from '../../core/hooks/useNotifications';
interface CustomProps {
  isExpand?: boolean;
  expandToggle: () => void;
  menuVisible?: boolean;
}

enum subMenus {
  payment = 'payment',
  settings = 'settings',
}

const SideMenuMob: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const authState = useSelector((state: State) => state.authUser);
  const [subMenu, toggleSubMenu] = React.useState<subMenus | null>(null);
  const { hasRole } = useAuth();
  const subMenuSelection = (e: any, selected: subMenus) => {
    e.preventDefault();
    if (selected === subMenu) {
      toggleSubMenu(null);
    } else {
      toggleSubMenu(selected);
    }
  };
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { notificationState } = useNotifications();
  const unreadNotificationCount =
    notificationState.notifications?.filter((p) => !p.isRead)?.length ?? 0;
  const onLanguageChange = (language: ILocale) => {
    //  i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    // const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    // const att = document.createAttribute('dir'); // Create a "class" attribute
    // att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    // h1.setAttributeNode(att);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <>
      <div
        className={`position-fixed top-0 left-0 bg-body zIndex-9 w-100 h-100 overflow-auto pb-5`}
      >
        <header className="d-lg-none header-mobile py-4 position-relative mb-4">
          <div className="container-fluid py-3">
            <div className="row gutter-12">
              <div className="col">
                <img
                  src={noImg}
                  className="img-fluid img-cover rounded-circle"
                ></img>
              </div>

              <div className="col-12 py-2">
                <h6 className="text-white mb-0 font-weight-bold">
                  {authState?.profile?.name}
                </h6>
                <p className="text-white font-md mb-0">
                  {authState?.profile?.email}
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="header-mob-bottom container-fluid pt-3 pb-5">
          <div className="bg-white shadow p-3 mb-2 rounded">
            {authState?.userType === UserType.Customer && (
              <Link
                to="/"
                onClick={() => window.location.reload()}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className=" mr-3 h5 mb-0">
                  <svg width="22" height="21" viewBox="0 0 22 21" fill="none">
                    <path
                      d="M4.95898 8.75H6.70898C8.45898 8.75 9.33398 7.875 9.33398 6.125V4.375C9.33398 2.625 8.45898 1.75 6.70898 1.75H4.95898C3.20898 1.75 2.33398 2.625 2.33398 4.375V6.125C2.33398 7.875 3.20898 8.75 4.95898 8.75Z"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.209 1.75H15.459C13.709 1.75 12.834 2.625 12.834 4.375V6.125C12.834 7.875 13.709 8.75 15.459 8.75H17.209C18.959 8.75 19.834 7.875 19.834 6.125V4.375"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.459 19.25H17.209C18.959 19.25 19.834 18.375 19.834 16.625V14.875C19.834 13.125 18.959 12.25 17.209 12.25H15.459C13.709 12.25 12.834 13.125 12.834 14.875V16.625C12.834 18.375 13.709 19.25 15.459 19.25Z"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.95898 19.25H6.70898C8.45898 19.25 9.33398 18.375 9.33398 16.625V14.875C9.33398 13.125 8.45898 12.25 6.70898 12.25H4.95898C3.20898 12.25 2.33398 13.125 2.33398 14.875V16.625"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                {translate('sideMenu.dashboard')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            {hasRole(UserPermissionTypes.customer_orders) && (
              <Link
                to={'/vehicles'}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="mr-3 h5 mb-0">
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path
                      d="M13.6552 2.47632H7.51273C5.33398 2.47632 4.85273 3.56132 4.57273 4.89132L3.58398 9.6251H17.584L16.5952 4.89132C16.3152 3.56132 15.834 2.47632 13.6552 2.47632Z"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.3336 12.0137C2.4561 10.7012 2.70985 9.625 5.00235 9.625H16.1673C18.4598 9.625 18.7136 10.7012 18.8361 12.0137L19.3261 17.3425C19.4223 18.3662 18.5998 19.25 17.5498 19.25H15.9048C14.9598 19.25 14.8286 18.8475 14.6623 18.3487L14.4873 17.8238C14.2423 17.1063 14.0848 16.625 12.8248 16.625H8.34485C7.08485 16.625 6.9011 17.1675 6.68235 17.8238L6.50735 18.3487C6.3411 18.8475 6.20985 19.25 5.26485 19.25H3.61985C2.56985 19.25 1.74735 18.3662 1.8436 17.3425L2.00985 15.54"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.58398 7H2.70898"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.459 7H17.584"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.584 2.625V4.375"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.27148 4.375H11.8965"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33398 13.125H7.95898"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.209 13.125H15.834"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                Vehicles
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}

            {authState.userType !== 'Salesperson' && (
              <>
                <Link
                  to="/staff-member"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-boy mr-3 h5 mb-0"></i>
                  Manage Staff
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              </>
            )}
            {hasRole(UserPermissionTypes.quotation_list) && (
              <Link
                to="/quotation"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-user mr-3 h5 mb-0"></i>
                Profile
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            <Link
              to="/change-password"
              className="d-flex align-items-center text-gray-700 mb-3"
            >
              <i className="icon-lock mr-3 h5 mb-0"></i>
              {translate('common.changePassword')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </Link>
          </div>

          <div className="bg-white shadow p-3 mb-2 rounded">
            <a
              href="#"
              className="d-flex align-items-center text-gray-700"
              onClick={() => {
                dispatch(logoutAction());
                dispatch(cartResetAction());
                history.push(RouteKeys.Login);
              }}
            >
              <i className="icon-logout mr-3 h5 mb-0"></i>
              {translate('sideMenu.logOut')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenuMob;
