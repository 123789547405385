import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AppTable, IColumn } from '../../../components/grid/app-table';
import { FilterValueType } from '../../../components/list-filters/saved-filters';
import {
  ICustomer,
  IDate,
  UserPermissionTypes,
} from '../../../core/api/models';
import { checkInAction, State } from '../../../core/store';
import { Dict } from '../../../models';
import '../../../assets/sass/listing.scss';
import '../../../assets/sass/grid.scss';
import { formatDate, getNormalDateformat } from '../../../utils/date-utils';
import {
  FilterTypes,
  OrderFilterComponent,
} from '../../vehicles/components/order-filter-component';
import { RouteKeys } from '../../routes/route-keys';
import { Props } from 'react-select';
import {
  IReturnItem,
  IReturnListApiResponse,
  ReturnReqCountByStatus,
  ReturnRequests,
} from '../../../models/order-models';
import { StatusTabs } from '../return-requests';
import { useAuth } from '../../../core/hooks';
import { usePaginatedDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { ContainerLoader } from '../../../components/loader';
import { translate } from '../../../utils/text-utils';
import { CurrencyFormatter } from '../../../components/currency-formatter';

export const ReturnRequestListing = (props: {
  page?: number;
  perPage?: number;
  filterTypes: FilterTypes[];
  date?: IDate;
  tab: string;
  setCount: (count: ReturnReqCountByStatus) => void;
  search?: string;
}) => {
  const authState = useSelector((state: State) => state.authUser);
  const [isRendered, setRender] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasRole } = useAuth();
  const [filters, setFilters] = useState<any>({});

  const {
    data: responseData,
    list: data,
    loading,
    handleScroll,
    reload,
    allPagesLoaded,
    loaded,
  } = usePaginatedDataLoader<
    IReturnListApiResponse<ReturnRequests>,
    ReturnRequests
  >({
    loadPage: () => {
      return api.order.getReturnRequest({
        filter: filters,
        requestID: props?.search,
        fromDate: props?.date?.startDate,
        toDate: props.date?.endDate,
        status: props.tab as any,
        perPage: props.perPage,
        page: props.page,
      });
    },
    defaultData: [],
    isError: (errData: IReturnListApiResponse<ReturnRequests>) =>
      errData.errorMsg
        ? errData.errorMsg || 'Unable to load return list'
        : undefined,
    resolveList: (resolveData: IReturnListApiResponse<ReturnRequests>) =>
      resolveData?.result!,
    startPage: 1,
    initialParams: [],
  });
  const onChangeFilter = () => {
    if (isRendered) {
      reload({
        ...filters,
        status: props.tab,
        searchID: props?.search,
        fromDate: props.date?.startDate,
        toDate: props?.date?.endDate,
        perPage: props?.perPage,
        page: props?.page,
      });
    }
  };

  useEffect(() => {
    if (loaded) {
      props.setCount(responseData?.returnReqCountByStatus);
    }
  }, [responseData.returnReqCountByStatus, loaded]);

  useEffect(() => {
    setRender(true);
  }, []);
  useEffect(() => {
    if (isRendered) {
      onChangeFilter();
    }
  }, [
    props?.search,
    filters,
    props?.page,
    props?.perPage,
    props?.tab,
    props?.date,
  ]);

  let columns: IColumn[] = [
    {
      key: 'requestID',
      title: translate('Creditline.RequestID'),
    },
    {
      key: 'customerCode',
      title: translate('Creditline.customerCode'),
    },
    {
      key: 'customerName',
      title: translate('Creditline.customerName'),
    },
    {
      key: 'requestedBy',
      title: translate('Creditline.requestedBy'),
    },
    {
      key: 'salesperson',
      title: translate('Creditline.salesPerson'),
    },
    {
      key: 'orderNumber',
      title: translate('duePayments.orderId'),
    },
    {
      key: 'returnDate',
      title: translate('orders.requestedOn'),
    },
    {
      key: 'requestedItems',
      title: translate('orders.requestedItems'),
    },
    {
      key: 'requestedQty',
      title: translate('orders.requestedQuantity'),
    },
    {
      key: 'returnTotalAmount',
      title: translate('orders.refundAmount'),
      columnRenderer: (data: any) => {
        return <CurrencyFormatter quantity={data?.returnTotalAmount} />;
      },
    },
    {
      key: 'approvedBy',
      title: translate('orders.approvedBy'),
    },
    {
      key: 'approveDate',
      title: translate('orders.approvedOn'),
    },
    {
      key: 'rejectedBy',
      title: translate('orders.requestedBy'),
    },
    {
      key: 'rejectedDate',
      title: translate('orders.requestedOn'),
    },
    {
      key: 'rejectReason',
      title: translate('orders.rejectedReason'),
    },
  ];
  if (props.tab === StatusTabs.Pending) {
    columns = columns.filter((p) => p.key !== 'approvedBy');
    columns = columns.filter((p) => p.key !== 'approveDate');
    columns = columns.filter((p) => p.key !== 'rejectedBy');
    columns = columns.filter((p) => p.key !== 'rejectedDate');
    columns = columns.filter((p) => p.key !== 'rejectReason');
  }
  if (props.tab === StatusTabs.Approved) {
    columns = columns.filter((p) => p.key !== 'rejectedBy');
    columns = columns.filter((p) => p.key !== 'rejectedDate');
    columns = columns.filter((p) => p.key !== 'rejectReason');
  }
  if (props.tab === StatusTabs.Rejected) {
    columns = columns.filter((p) => p.key !== 'approvedBy');
    columns = columns.filter((p) => p.key !== 'approveDate');
  }

  // const checkUserRole = (returnData) => {
  //   hasRole(UserRoleTypes.return_view) &&
  //     history.push(`${RouteKeys.ReturnDetails}/${returnData.returnID}`);
  // };

  return (
    <>
      <div className="list-head">
        <OrderFilterComponent
          filter={filters}
          onFilterChange={(filter) => {
            setFilters(filter);
          }}
          filterTypes={props.filterTypes}
        ></OrderFilterComponent>
      </div>
      <div className="table-block grid-table table-hover">
        <AppTable
          onRowClick={(returnData: IReturnItem) => {
            if (hasRole(UserPermissionTypes.return_view)) {
              history.push(`${RouteKeys.ReturnDetails}/${returnData.returnID}`);
            }
          }}
          data={data}
          columns={columns}
          showEmptyResult
          isLoading={loading}
        ></AppTable>
        {loading && (
          <div className="py-5">
            <ContainerLoader />
          </div>
        )}
      </div>
    </>
  );
};
