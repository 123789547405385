import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserPermissionTypes, UserType } from '../../core/api/models';
import { useAuth } from '../../core/hooks';
import { cartResetAction, logoutAction, State } from '../../core/store';
import { translate } from '../../utils/text-utils';
import { RouteKeys } from '../routes/route-keys';
import './SideMenu.scss';
import noProfile from '../../assets/images/no-profile.png';
import { decodeHtmlEntities } from '../../utils/common';

interface CustomProps {
  isExpand?: boolean;
  expandToggle: () => void;
  menuVisible?: boolean;
}

enum subMenus {
  payment = 'payment',
  settings = 'settings',
  salespersonSettings = 'salespersonsettings',
  schedule = 'schedule',
  reports = 'reports',
  sales = 'sales',
}

const SideMenu: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const [subMenu, toggleSubMenu] = React.useState<subMenus | null>(null);
  const authState = useSelector((state: State) => state.authUser);
  const { hasRole, isSalesPerson, userToken } = useAuth();

  const subMenuSelection = (e: any, selected: subMenus) => {
    e.preventDefault();
    if (selected === subMenu) {
      toggleSubMenu(null);
    } else {
      toggleSubMenu(selected);
    }
  };
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    if (!props.isExpand) {
      toggleSubMenu(null);
    }
  }, [props.isExpand]);
  const { pathname } = useLocation();
  return (
    <>
      <div
        className={`sidemenu bg-white border-right d-none d-lg-block ${props.isExpand === true ? 'menuExpand' : ''
          } ${props.menuVisible === true ? 'menuVisible' : ''}`}
      >
        <div className="sidemenu-inner">
          <ul className="list-unstyled p-0 m-0 menu-list py-2">
            {userToken && (
              <li>
                <Link
                  to="/"
                  className={`d-flex align-items-center ${pathname === '/' ? 'active' : ''
                    }`}
                >
                  <i>
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path
                        d="M4.95898 8.75H6.70898C8.45898 8.75 9.33398 7.875 9.33398 6.125V4.375C9.33398 2.625 8.45898 1.75 6.70898 1.75H4.95898C3.20898 1.75 2.33398 2.625 2.33398 4.375V6.125C2.33398 7.875 3.20898 8.75 4.95898 8.75Z"
                        stroke="#202020"
                        strokeWidth="1.2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.209 1.75H15.459C13.709 1.75 12.834 2.625 12.834 4.375V6.125C12.834 7.875 13.709 8.75 15.459 8.75H17.209C18.959 8.75 19.834 7.875 19.834 6.125V4.375"
                        stroke="#202020"
                        strokeWidth="1.2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.459 19.25H17.209C18.959 19.25 19.834 18.375 19.834 16.625V14.875C19.834 13.125 18.959 12.25 17.209 12.25H15.459C13.709 12.25 12.834 13.125 12.834 14.875V16.625C12.834 18.375 13.709 19.25 15.459 19.25Z"
                        stroke="#202020"
                        strokeWidth="1.2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.95898 19.25H6.70898C8.45898 19.25 9.33398 18.375 9.33398 16.625V14.875C9.33398 13.125 8.45898 12.25 6.70898 12.25H4.95898C3.20898 12.25 2.33398 13.125 2.33398 14.875V16.625"
                        stroke="#202020"
                        strokeWidth="1.2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </i>
                  {translate('sideMenu.dashboard')}
                </Link>
              </li>
            )}

            {/* {hasRole(UserPermissionTypes.customer_orders) && ( */}
            <li>
              <Link
                to={'/vehicles'}
                className={`d-flex align-items-center ${pathname === '/vehicles' ? 'active' : ''
                  }`}
              >
                <i>
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path
                      d="M13.6552 2.47632H7.51273C5.33398 2.47632 4.85273 3.56132 4.57273 4.89132L3.58398 9.6251H17.584L16.5952 4.89132C16.3152 3.56132 15.834 2.47632 13.6552 2.47632Z"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.3336 12.0137C2.4561 10.7012 2.70985 9.625 5.00235 9.625H16.1673C18.4598 9.625 18.7136 10.7012 18.8361 12.0137L19.3261 17.3425C19.4223 18.3662 18.5998 19.25 17.5498 19.25H15.9048C14.9598 19.25 14.8286 18.8475 14.6623 18.3487L14.4873 17.8238C14.2423 17.1063 14.0848 16.625 12.8248 16.625H8.34485C7.08485 16.625 6.9011 17.1675 6.68235 17.8238L6.50735 18.3487C6.3411 18.8475 6.20985 19.25 5.26485 19.25H3.61985C2.56985 19.25 1.74735 18.3662 1.8436 17.3425L2.00985 15.54"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.58398 7H2.70898"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.459 7H17.584"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.584 2.625V4.375"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.27148 4.375H11.8965"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33398 13.125H7.95898"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.209 13.125H15.834"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                { }
                {/* {translate('sideMenu.profile')} */} Vehicles
              </Link>
            </li>
            {/* )} */}

            {/* {hasRole(UserPermissionTypes.staff_list) &&
              authState.userType === UserType.Customer && ( */}
            <li>
              <Link
                to="/staff-member"
                className="d-flex align-items-center"
              >
                <i>
                  <svg
                    width="38"
                    height="37"
                    viewBox="0 0 38 37"
                    fill="none"
                    style={{ marginLeft: '-6px' }}
                  >
                    <rect
                      x="0.5"
                      width="37"
                      height="37"
                      rx="6"
                      fill="#F0F3F8"
                    />
                    <rect
                      x="0.5"
                      width="37"
                      height="37"
                      rx="6"
                      fill="white"
                    />
                    <path
                      d="M19.1792 11.465C19.5992 12.0863 19.8442 12.83 19.8442 13.635C19.8354 15.735 18.1817 17.4413 16.0992 17.5113C16.0117 17.5025 15.9067 17.5025 15.8105 17.5113C13.728 17.4413 12.0742 15.735 12.0742 13.635C12.0742 11.4913 13.8067 9.75 15.9592 9.75"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.4419 11.5C24.1394 11.5 25.5044 12.8738 25.5044 14.5625C25.5044 16.2163 24.1919 17.5638 22.5556 17.625C22.4856 17.6163 22.4069 17.6163 22.3281 17.625"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.7248 20.74C9.60734 22.1575 9.60734 24.4675 11.7248 25.8762C14.1311 27.4862 18.0773 27.4862 20.4836 25.8762C22.6011 24.4587 22.6011 22.1488 20.4836 20.74C18.0861 19.1388 14.1398 19.1388 11.7248 20.74Z"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.1309 25.5C24.7609 25.3687 25.3559 25.115 25.8459 24.7387C27.2109 23.715 27.2109 22.0262 25.8459 21.0025C25.3646 20.635 24.7784 20.39 24.1571 20.25"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                {/* {translate('sideMenu.staff')} */} Manage Staff
              </Link>
            </li>
            {/* )} */}

            {/* {hasRole(UserPermissionTypes.quotation_list) && ( */}
            <li>
              <Link
                to="/profile"
                className={`d-flex align-items-center pt-0 ${pathname === '/profile' ? 'active' : ''
                  }`}
              >
                <i>
                  <svg
                    width="38"
                    height="37"
                    viewBox="0 0 38 37"
                    fill="none"
                    style={{ marginLeft: '-6px' }}
                  >
                    <rect
                      x="0.5"
                      width="37"
                      height="37"
                      rx="6"
                      fill="#F0F3F8"
                    />
                    <rect
                      x="0.5"
                      width="37"
                      height="37"
                      rx="6"
                      fill="white"
                    />
                    <path
                      d="M21.1564 15.0349C21.3489 15.4199 21.4627 15.8574 21.4627 16.3212C21.4539 17.8699 20.2377 19.1387 18.6977 19.1824C18.6364 19.1737 18.5577 19.1737 18.4877 19.1824C16.9477 19.1299 15.7227 17.8699 15.7227 16.3212C15.7227 14.7374 17.0002 13.4512 18.5927 13.4512"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4825 24.9577C22.925 26.384 20.86 27.2502 18.585 27.2502C16.31 27.2502 14.245 26.384 12.6875 24.9577C12.775 24.1352 13.3 23.3302 14.2363 22.7002C16.6338 21.1077 20.5538 21.1077 22.9338 22.7002C23.87 23.3302 24.395 24.1352 24.4825 24.9577Z"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.584 13.25C10.4902 14.7112 9.83398 16.5312 9.83398 18.5C9.83398 23.33 13.754 27.25 18.584 27.25C23.414 27.25 27.334 23.33 27.334 18.5C27.334 13.67 23.414 9.75 18.584 9.75C17.3327 9.75 16.134 10.0125 15.0577 10.4937"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                {/* {translate('sideMenu.quotation')} */} Profile
              </Link>
            </li>
            {/* )} */}
            <li>
              <Link
                to="/change-password"
                className="font-weight-normal d-flex py-2"
              >
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M2.78711 5.88874C2.78711 5.17124 3.32963 4.3575 4.00338 4.08625L8.87707 2.09128C9.97082 1.64503 11.2046 1.64503 12.2983 2.09128L17.1721 4.08625C17.8371 4.3575 18.3884 5.17124 18.3884 5.88874V9.72997C18.3884 14.0087 15.2821 18.0162 11.0383 19.1887C10.7496 19.2675 10.4346 19.2675 10.1458 19.1887C5.90212 18.0162 2.79587 14.0087 2.79587 9.72997"
                      stroke="#292D32"
                      strokeWidth="1.1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.584 10.9375C11.5505 10.9375 12.334 10.154 12.334 9.1875C12.334 8.221 11.5505 7.4375 10.584 7.4375C9.61746 7.4375 8.83398 8.221 8.83398 9.1875C8.83398 10.154 9.61746 10.9375 10.584 10.9375Z"
                      stroke="#292D32"
                      strokeWidth="1.1"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.584 10.9375V13.5625"
                      stroke="#292D32"
                      strokeWidth="1.1"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                {translate('common.changePassword')}
              </Link>
            </li>

            <li>
              <a
                href="#"
                className="d-flex align-items-center"
                onClick={() => {
                  dispatch(logoutAction());
                  dispatch(cartResetAction());
                  history.push(RouteKeys.Login);
                }}
              >
                <i>
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path
                      d="M1.83398 10.5H13.104"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.1533 7.5686L14.0846 10.4999L11.1533 13.4311"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.8971 11.375V13.3525C18.8971 17.2638 17.3309 18.83 13.4196 18.83H13.3059C9.78839 18.83 8.16961 17.5613 7.88086 14.4638"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.87207 6.61496C8.14332 3.46496 9.76208 2.17871 13.3058 2.17871H13.4196C17.3308 2.17871 18.8971 3.74496 18.8971 7.65621"
                      stroke="#202020"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                {translate('sideMenu.logOut')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
