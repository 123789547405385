import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';
import Higher from '../higherOrder';
import CartConfirmationScreen from '../home/cart/cart-confirmation-screen';
import QuoteConfirmationScreen from '../home/cart/quote-confirmation-screen';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import { UserPermissionTypes } from '../../core/api/models';

const Home = lazy(() => import('../home'));
const Dashboard = lazy(() => import('../dashboard/dashboard-screen'));
const SetNewPassword = lazy(() => import('../auth/setNewPassword'));
const Login = lazy(() => import('../auth/Login'));
const PasswordForm = lazy(() => import('../auth/passwordForm'));
const SignUp = lazy(() => import('../auth/SignUp'));
const ForgotPassword = lazy(() => import('../auth/ForgotPassword'));
const NotFound = lazy(() => import('../notfound'));
const Categories = lazy(() => import('../categories'));
const SearchResult = lazy(
  () => import('../search-result/search-result-screen')
);
const Parts = lazy(() => import('../parts'));
const MyAccount = lazy(() => import('../my-account'));
const Profile = lazy(() => import('../profile/profile-screen'));
const QuotationDetails = lazy(
  () => import('../profile/quotation-details-screen')
);
const Vehicles = lazy(() => import('../vehicles'));
const VehicleDetails = lazy(() => import('../vehicles/vehicle-details-screen'));
const Checkout = lazy(() => import('../checkout/checkout-screen'));
const Terms = lazy(() => import('../my-account/terms'));
const StaffMember = lazy(() => import('../account-settings/staff-member'));
const StaffProfileDetails = lazy(
  () => import('../account-settings/staff-member/staff-profile-details-screen')
);
const ManageSalesPerson = lazy(
  () =>
    import('../account-settings/manage-salesperson/manage-sales-person-screen')
);
const DuePayments = lazy(() => import('../payment-center/due-payments-screen'));
const PaymentHistory = lazy(
  () => import('../payment-center/payment-history/payment-history-screen')
);
const PaymentHistoryDetails = lazy(
  () =>
    import('../payment-center/payment-history/payment-history-details-screen')
);
const CreditNote = lazy(
  () => import('../payment-center/creditnote/creditnote-screen')
);
const CreditNoteDetails = lazy(
  () => import('../payment-center/creditnote/creditnote-details-screen')
);
const DueCustomers = lazy(
  () => import('../due-payments/due-payment-customers')
);
const Faq = lazy(() => import('../faq'));
const BulkOrder = lazy(() => import('../bulk-order'));
const Customers = lazy(() => import('../customers/customers-screen'));
const ScheduleManagement = lazy(
  () => import('../schedule-management/manage-schedule-screen')
);
const ScheduleList = lazy(
  () => import('../schedule-management/schedule-list-screen')
);

const NewVisitationRequests = lazy(
  () => import('../schedule-management/new-visitation-screen')
);
const VisitationRequestDetails = lazy(
  () => import('../schedule-management/components/visitation-request-details')
);
const SalespersonAccountSettings = lazy(
  () => import('../salesperson-account-settings/account-settings-screen')
);
const ReturnRequests = lazy(() => import('../return/return-requests'));
const ReturnDetails = lazy(
  () => import('../return-details/return-details-screen')
);

const CreditRequests = lazy(
  () => import('../credit-requests/credit-request-screen')
);
const CreditDetails = lazy(
  () => import('../credit-request-details/credit-request-details-screen')
);

const ToDo = lazy(() => import('../to-do/to-do-screen'));

const DuePaymentDetails = lazy(
  () =>
    import('../payment-center/due-payment-details/due-payment-details-screen')
);
const CreditlineExtensionRequestScreen = lazy(
  () => import('../payment-center/creditline-extension-request-screen')
);
const CreditlineExtensionRequestDetails = lazy(
  () =>
    import('../payment-center/components/creditline-extension-request-details')
);

const ConfirmQuotationScreen = lazy(
  () => import('../profile/place-order-confirmation-screen')
);

const PermissionNeeded = lazy(() => import('../permission-needed'));
const Offers = lazy(() => import('../offers/offers-screen'));
const ChangePassword = lazy(
  () => import('../change-password/change-password-screen')
);
const Sales = lazy(() => import('../reports/sales/sales'));
const ReportsPayment = lazy(() => import('../reports/payments/payment'));
const ReportSalesPerson = lazy(() => import('../reports/sales-person/sales-person'));
const CreditExtensionDetail = lazy(
  () => import('../reports/payments/credit-extension-detail')
);
const PaymentDueDetail = lazy(() => import('../reports/payments/payment-due-detail'));
const PaymentCollectionDetail = lazy(
  () => import('../reports/payments/payment-collection-detail')
);
const ReportsCustomers = lazy(() => import('../reports/customers/customers'));
const CustomerUsageDetailPage = lazy(
  () => import('../reports/customers/customer-usage-detail')
);
const PartsGroupCustomer = lazy(
  () => import('../reports/customers/parts-group-customer')
);
const ProductsDeletedList = lazy(
  () => import('../reports/customers/products-deleted-list')
);
const ConversionRatesDetail = lazy(
  () => import('../reports/sales/conversion-detail')
);
const ConversionRatesDetailSale = lazy(
  () => import('../reports/sales/conversion-detail-sale')
);

const CustomerDeletedProductCart = lazy(
  () => import('../reports/customers/products-deleted-list')
);
const StatusDetails = lazy(
  () => import('../reports/sales/status-details')
);
const StatusOrderDetails = lazy(
  () => import('../reports/sales/status-order-detail')
);
const CutomerGroupDetails = lazy(
  () => import('../reports/sales/customer-group-details')
);

const YearPerformanceDetails = lazy(
  () => import('../reports/sales-person/year-perfomance-detail')
);

const VisitReportDetail = lazy(
  () => import('../reports/sales-person/visit-report-details')
);

const SalespersonActivityView = lazy(
  () => import('../reports/sales-person/sales-person-activity-view')
);

const CustomerActivityView = lazy(
  () => import('../reports/sales-person/customer-activity-view')
);

const SalespersonActivityTrack = lazy(
  () => import('../reports/sales-person/sales-person-activity-track')
);

const ConversionRatesCustomerOrderDetail = lazy(() => import('../reports/sales/conversion-rate-customer-order'));
const ConversionRatesCustomerOrderSaleDetail = lazy(() => import('../reports/sales/conversion-rate-customer-order-sale'));
const StatusCustomerOrderDetail = lazy(() => import('../reports/sales/status-customer-order-detail'));
const PaymentStatus = lazy(() => import('../checkout/payment-status'));
const DuePaymentStatus = lazy(() => import('../payment-center/components/due-payment-status'));
const IframeGateway = lazy(
  () => import('../checkout/components/iframe-gateway')
);

const IframeGatewayDuePayments = lazy(
  () => import('../payment-center/components/process-payment/duePayment-iframe-gateway')
);
const FrequentBoughtList = lazy(() => import('../home/frequent-bought/FrequentBoughtList'));

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          {/* <AuthenticateRoute exact path={RouteKeys.Home} component={Home} /> */}
          <AuthenticateRoute
            exact
            path={RouteKeys.PermissionNeeded}
            component={PermissionNeeded}
          />
          <AuthenticateRoute
            exact
            onlyForSalesperson
            path={RouteKeys.Dashboard}
            component={Dashboard}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.Home}`}
            component={Home}
            needCheckIn
          />
          <RedirectIfAuthenticatedRoute
            path={RouteKeys.Login}
            component={Login}
          />

          <Route exact path={RouteKeys.SignUp} component={SignUp} />
          <Route path={RouteKeys.PasswordForm} component={PasswordForm} />
          <Route path={RouteKeys.SetNewPassword} component={SetNewPassword} />
          <Route path={RouteKeys.ChangePassword} component={ChangePassword} />
          <Route
            exact
            path={RouteKeys.ForgotPassword}
            component={ForgotPassword}
          />
          {/* <AuthenticateRoute
            exact
          
            path={RouteKeys.Dashboard}
            component={Dashboard}
          /> */}
          <AuthenticateRoute
            exact
            needCheckIn
            path={`${RouteKeys.Categories}/:brandID/:carid/:mid?`}
            component={Categories}
          />
          <AuthenticateRoute
            exact
            needCheckIn
            path={RouteKeys.SearchResult}
            component={SearchResult}
          />

          <AuthenticateRoute
            exact
            path={`${RouteKeys.Parts}/:brandID/:carid/:mid?/:groupid/:groups?`}
            component={Parts}
          />
          <AuthenticateRoute exact path={RouteKeys.ToDo} component={ToDo} />
          <AuthenticateRoute path={RouteKeys.MyAccount} component={MyAccount} />
          <AuthenticateRoute
            path={RouteKeys.ConfirmCart}
            component={CartConfirmationScreen}
          />
          <AuthenticateRoute
            path={RouteKeys.ConfirmQuote}
            component={QuoteConfirmationScreen}
          />
          <AuthenticateRoute
            path={`${RouteKeys.Profile}`}
            component={Profile}
            requiredPermission={UserPermissionTypes.quotation_list}
          />
          <AuthenticateRoute
            path={`${RouteKeys.QuotationDetails}/:requistID`}
            component={QuotationDetails}
            requiredPermission={UserPermissionTypes.quotation_view}
          />
          <AuthenticateRoute
            path={`${RouteKeys.ConfirmQuotationOrder}/:requestID`}
            component={ConfirmQuotationScreen}
          />
          {/* <AuthenticateRoute path={RouteKeys.Orders} component={OrderStatus} /> */}
          <AuthenticateRoute path={RouteKeys.Higher} component={Higher} />

          <AuthenticateRoute
            path={`${RouteKeys.Vehicles}/:tab?`}
            component={Vehicles}
            requiredPermission={UserPermissionTypes.customer_orders}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.CustomerDetails}/:vinID`}
            component={VehicleDetails}
          />
          <AuthenticateRoute
            path={`${RouteKeys.Checkout}/:orderID`}
            component={Checkout}
          />
          <AuthenticateRoute
            path={`${RouteKeys.PaymentGatewayStatus}`}
            component={PaymentStatus}
          />
          <AuthenticateRoute
            path={`${RouteKeys.DuePaymentStatus}`}
            component={DuePaymentStatus}
          />
          <AuthenticateRoute
            path={`${RouteKeys.IframeGateway}`}
            component={IframeGateway}
          />

          <AuthenticateRoute
            path={`${RouteKeys.IframeGatewayDuePayment}`}
            component={IframeGatewayDuePayments}
          />
          <AuthenticateRoute
            path={`${RouteKeys.DuePaymentDetails}/:orderID/:userToken?`}
            component={DuePaymentDetails}
          />
          <AuthenticateRoute
            path={`${RouteKeys.DuePayments}/:userToken`}
            component={DuePayments}
          />
          <AuthenticateRoute exact path={RouteKeys.Terms} component={Terms} />

          <AuthenticateRoute
            requiredPermission={UserPermissionTypes.view_duepayments}
            exact
            path={RouteKeys.DuePayments}
            component={DuePayments}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.PaymentHistory}
            component={PaymentHistory}
            requiredPermission={UserPermissionTypes.list_paymenthistory}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.PaymentHistory}/:id`}
            component={PaymentHistoryDetails}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.CreditNote}
            component={CreditNote}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.CreditNote}/:id`}
            component={CreditNoteDetails}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.DueCustomers}
            component={DueCustomers}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.BulkOrder}
            component={BulkOrder}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.StaffMember}
            component={StaffMember}
            requiredPermission={UserPermissionTypes.staff_list}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.StaffPersonalInfo}
            component={StaffProfileDetails}
          // requiredPermission={UserPermissionTypes.staff_list}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.ManageSalesPerson}/:userCode?`}
            component={ManageSalesPerson}
            requiredPermission={UserPermissionTypes.list_salespersons}
          />
          <AuthenticateRoute exact path={RouteKeys.Faq} component={Faq} />
          <AuthenticateRoute
            exact
            requiredPermission={UserPermissionTypes.place_order_for_customers}
            path={RouteKeys.Customers}
            component={Customers}
          />


          <AuthenticateRoute
            exact
            path={RouteKeys.Dashboard}
            component={Dashboard}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.ScheduleManagement}
            component={ScheduleManagement}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Schedules}
            component={ScheduleList}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.NewVisitationRequests}
            component={NewVisitationRequests}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.VisitationRequestDetails}/:scheduleDetailID`}
            component={VisitationRequestDetails}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.SalespersonAccountSettings}`}
            component={SalespersonAccountSettings}
            requiredPermission={UserPermissionTypes.view_account_personal_info}
          />

          <AuthenticateRoute
            exact
            path={`${RouteKeys.CreditlineExtensionRequestScreen}`}
            component={CreditlineExtensionRequestScreen}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.CreditlineExtensionRequestDetails}/:requestID`}
            component={CreditlineExtensionRequestDetails}
          />

          <AuthenticateRoute
            exact
            path={`${RouteKeys.Returns}/:tab?`}
            component={ReturnRequests}
            requiredPermission={UserPermissionTypes.return_list}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.ReturnDetails}/:returnID`}
            component={ReturnDetails}
            requiredPermission={UserPermissionTypes.return_view}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.CreditRequests}/:tab?`}
            component={CreditRequests}
            requiredPermission={UserPermissionTypes.list_creditrequest}
          />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.CreditDetails}/:requestID`}
            component={CreditDetails}
          />
          <AuthenticateRoute exact path={RouteKeys.Offers} component={Offers} />
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.Sales}/:tab?`}
            component={Sales}
            requiredPermission={UserPermissionTypes.report_sales} />
          <AuthenticateRoute exact path={`${RouteKeys.ReportsPayment}/:tab?`} component={ReportsPayment} requiredPermission={UserPermissionTypes.report_payment} />
          <Route path={RouteKeys.CreditExtensionDetail} component={CreditExtensionDetail} />
          <Route path={RouteKeys.PaymentDueDetail} component={PaymentDueDetail} />
          <AuthenticateRoute
            exact
            path={`${RouteKeys.ReportsCustomers}/:tab?`}
            component={ReportsCustomers}
            requiredPermission={UserPermissionTypes.report_customer}
          />
          <Route
            path={RouteKeys.PaymentCollectionDetail}
            component={PaymentCollectionDetail}
          />
          <Route path={RouteKeys.CustomerUsageDetailPage} component={CustomerUsageDetailPage} />
          <Route path={RouteKeys.CustomerProductDeletedCart} component={CustomerDeletedProductCart} />
          <Route path={RouteKeys.CustomerConversionOrderDetail} component={ConversionRatesCustomerOrderDetail} />
          <Route path={RouteKeys.CustomerSalesConversionOrderDetail} component={ConversionRatesCustomerOrderSaleDetail} />
          <Route
            path={RouteKeys.PartsGroupCustomer}
            component={PartsGroupCustomer}
          />
          <Route
            path={RouteKeys.StatusCustomerOrderDetail}
            component={StatusCustomerOrderDetail}
          />
          <Route
            path={RouteKeys.ProductsDeletedList}
            component={ProductsDeletedList}
          />
          <AuthenticateRoute
            requiredPermission={UserPermissionTypes.report_salesperson}
            path={`${RouteKeys.SalesPersonReport}/:tab?`}
            component={ReportSalesPerson}
          />

          <Route
            path={RouteKeys.YearPerformanceDetails}
            component={YearPerformanceDetails}
          />

          <Route
            path={RouteKeys.SalesPersonActivityView}
            component={SalespersonActivityView}
          />

          <Route
            path={RouteKeys.SalesPersonActivityTrack}
            component={SalespersonActivityTrack}
          />
          <Route
            path={RouteKeys.CustomerActivityView}
            component={CustomerActivityView}
          />
          <Route
            path={RouteKeys.VisitReportDetail}
            component={VisitReportDetail}
          />

          <Route
            path={RouteKeys.StatusReportDetail}
            component={StatusDetails}
          />
          <Route
            path={RouteKeys.StatusOrderDetail}
            component={StatusOrderDetails}
          />
          <Route
            path={RouteKeys.CustomerGroupDetail}
            component={CutomerGroupDetails}
          />

          <Route
            path={RouteKeys.CustomerConversionDetails}
            component={ConversionRatesDetail}
          />
          <Route
            path={RouteKeys.CustomerSalesConversionDetails}
            component={ConversionRatesDetailSale}
          />
          <AuthenticateRoute
            path={RouteKeys.FrequentlyBoughtList}
            component={FrequentBoughtList}
          />
          <AuthenticateRoute
            path={RouteKeys.FrequentlyBoughtList}
            component={FrequentBoughtList}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
