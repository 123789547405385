import { FilterTypes } from '../../../containers/vehicles/components/order-filter-component';
import {
  IBrandWiseTotal,
  ISalesManReport,
  ISalesPerformanceReport,
  SKUReportData,
  TopSalesPersonData,
} from '../models/report';
import { ApiResponse } from '../models';
import ApiService from './service';
import * as http from './http';
import { da } from 'date-fns/locale';
import {
  CustomerUsage,
  CustomerUsageDetail,
  DeletedCartData,
  DeletedProductCartCustomer,
  InactiveCustomer,
} from '../models/customer';

export class CustomerSevice extends ApiService {
  //get customer usage report

  public async getCustomerUsage(
    params: any
  ): Promise<ApiResponse<CustomerUsage[]>> {
    const url = `${this.apiDomain}/report/customerusage/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes');
    }
    if (params?.month) {
      data.append('month', 'Yes');
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes');
    }
    if (params?.week) {
      data.append('week', 'Yes');
    }
    data.append('brandID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<CustomerUsage[]>>(
      url,
      data
    );
    return response?.data;
  }

  //get customer usage details

  public async getCustomerUsageDetail(
    params: any
  ): Promise<ApiResponse<CustomerUsageDetail[]>> {
    const url = `${this.apiDomain}/report/subCustomerUsageGetCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<
      ApiResponse<CustomerUsageDetail[]>
    >(url, data);
    return response?.data;
  }

  //get customer deleted products of cart

  public async getCustomerDeletedProductCart(
    params: any
  ): Promise<ApiResponse<DeletedProductCartCustomer[]>> {
    const url = `${this.apiDomain}/report/subCustomerUsageGetCartDeleted/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<
      ApiResponse<DeletedProductCartCustomer[]>
    >(url, data);
    return response?.data;
  }

  //Customer inactive report

  public async getInactiveCustomers(
    params: any
  ): Promise<ApiResponse<InactiveCustomer>> {
    const url = `${this.apiDomain}/report/inactivecustomeruser/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }
    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<InactiveCustomer>>(
      url,
      data
    );
    return response?.data;
  }

  public async getDeletedCartItemReport(
    params: any
  ): Promise<ApiResponse<DeletedCartData[]>> {
    const url = `${this.apiDomain}/report/deletedCart/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<DeletedCartData[]>>(
      url,
      data
    );
    return response?.data;
  }
}
