import { toastr } from 'react-redux-toastr';
import { FilterTypes } from '../../../containers/vehicles/components/order-filter-component';
import {
  ApiResponse,
  BannerResponse,
  Brand,
  DocumentList,
  DocumentResponse,
  FreqProdList,
  Group,
  GroupApiResponse,
  GroupResponse,
  MediaFile,
  Model,
  MyList,
  Parts,
  PartsApiResponse,
  PartsResponse,
  Product,
  ProductApiResponse,
  ProductFilter,
  ProductResponse,
  UsersList,
  TermsResponse,
  UserApiResponse,
  UserResponse,
  ICompanyProfile,
  IUserWithRole,
  ICustomer,
  ICustomerFilterParams,
  ICustomerRequestFilterParams,
  CustomerRequestResponse,
  IReason,
  DeclineRequestParam,
  ISalesman,
  IAddApproveParam,
  IUseBrandDetails,
  IPersonalInfo,
  IUserLog,
  IUserLogParams,
  IScheduleResponse,
  IScheduleListParams,
  PaginatedApiResponse,
  IScheduleCustomerListResponse,
  IDayScheduleItem,
  IUserPermission,
  ICustomerDashboard,
} from '../models';
import * as http from './http';
import ApiService from './service';
import { IPerformanceGraphRequestParams } from '../../../models/order-models';

export class UserService extends ApiService {
  public async getTerms(): Promise<TermsResponse> {
    const url = `${this.apiDomain}/user/gettermsofuse`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<TermsResponse>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getAllStaffs(): Promise<any> {
    toastr.error('Error', 'Not implemented');
  }

  // get user type permission
  public async getUserPermission(): Promise<IUserPermission> {
    const url = `${this.apiDomain}/user/getCustomerProfileEditPermission`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }


  public async getRequestedByUsers(): Promise<IUserWithRole[]> {
    const url = `${this.apiDomain}/order/requestedbylist`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result?.requestedByList;
  }

  public async getBrandsForFilter(): Promise<any> {
    const url = `${this.apiDomain}/index/listBrands`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getProfileDetails(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/companyprofile`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getCustomerDetails(userID?: string): Promise<ICustomer> {
    const url = `${this.apiDomain}/salesperson/preview`;
    const data = new FormData();

    data.append('userToken', '');
    data.append('userID', userID);

    const response = await http.postAsFormData<ApiResponse<ICustomer>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getBrandDetails(userID?: string): Promise<IUseBrandDetails[]> {
    const url = `${this.apiDomain}/salesperson/getUserBranches/`;
    const data = new FormData();

    data.append('userToken', '');
    data.append('userID', userID);

    const response = await http.postAsFormData<ApiResponse<IUseBrandDetails[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getSalesmenList(): Promise<ISalesman[]> {
    const url = `${this.apiDomain}/salesperson/listSalesPerson/`;
    const data = new FormData();

    const response = await http.postAsFormData<ApiResponse<ISalesman[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async editProfileDetails(
    fields: ICompanyProfile,
    userToken?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/user/companyprofile`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('action', 'update');
    data.append('companyName', fields?.companyName);
    data.append('fullName', fields?.fullName);
    data.append('tradeno', fields?.tradeno);
    data.append('nationalUnifiedNumber', fields?.nationalUnifiedNumber);
    data.append('vatNo', fields?.vatNo);
    data.append('userEmail', fields?.userEmail);
    data.append('userDialcode', fields?.userPhone?.dialCode);
    data.append('userPhone', fields?.userPhone?.entered);
    data.append('userCity', fields?.userCity);
    data.append('stateID', fields?.stateID);
    data.append('countryID', fields?.countryID);
    data.append('dealerGFIN', fields?.dealerGFIN);
    const response = await http.postAsFormData<ApiResponse<ICompanyProfile>>(
      url,
      data
    );
    return response;
  }


  public async editName(data: any) {
    const formData = new FormData();
    formData.append('userFirstName', ` ${data.userFirstName}`);
    formData.append('userLastName', `${data.userLastName}`);
    const url = `${this.apiDomain}/user/editUserName`;
    const response = await http.postAsFormData<ApiResponse<any>>(url, formData);
    return response;
  }

  public async editProfilePicture(file: File) {
    const formData = new FormData();
    formData.append('userProfilePicture', file);
    formData.append('action', `update`);
    const url = `${this.apiDomain}/user/editProfileImage`;
    const response = await http.postAsFormData<ApiResponse<any>>(url, formData);
    return response;
  }

  public async editCompanyProfile(data: any) {
    const formData = new FormData();

    formData.append('companyName', ` ${data.companyName}`);
    formData.append('fullName', ` ${data.userFullName}`);
    formData.append('tradeno', ` ${data.crn}`);
    formData.append('vatNo', ` ${data.vatNo}`);
    formData.append('userCity', ` ${data.userCity}`);
    formData.append('countryID', ` ${data.userCountry}`);
    formData.append('stateID', ` ${data.stateID}`);
    formData.append('userDialcode', `${data.userPhoneDialCode}`);
    formData.append('userPhone', `${data.userPhone}`);
    formData.append('userEmail', `${data.userEmail}`);
    formData.append('dealerGFIN', `${data.dealerGFIN}`);



    formData.append('action', 'update');
    const url = `${this.apiDomain}/user/companyprofile`;
    const response = await http.postAsFormData<ApiResponse<any>>(url, formData);
    return response?.data;
  }

  public async editCompanyAddress(data: any) {
    const formData = new FormData();
    formData.append('userAdress1', ` ${data.userAddress}`);
    formData.append('userCity', `${data.userCity}`);
    formData.append('countryID', `${data.userCountry}`);
    formData.append('userZip', `${data.userZip}`);
    const url = `${this.apiDomain}/user/editaddress`;
    const response = await http.postAsFormData<ApiResponse<any>>(url, formData);
    return response;
  }
  public async uploadDocument(
    file: File,
    documentTypeID: number
  ): Promise<DocumentList[]> {
    const formData = new FormData();
    formData.append('documentUrl', file);
    formData.append('documentMaster', documentTypeID?.toString());
    const url = `${this.apiDomain}/user/documentadd`;
    const response = await http.postAsFormData<ApiResponse<DocumentList[]>>(
      url,
      formData
    );
    return response.data?.list;
  }

  public async getDocument(): Promise<DocumentResponse> {
    const formData = new FormData();
    const url = `${this.apiDomain}/user/userdocuments`;
    const response = await http.postAsFormData<ApiResponse<DocumentResponse>>(
      url,
      formData
    );
    return {
      list: response.data?.result?.list,
      type: response.data?.result?.type,
    };
  }

  public async documentDelete(documentID: number): Promise<DocumentList[]> {
    const formData = new FormData();
    formData.append('documentID', documentID?.toString());
    const url = `${this.apiDomain}/user/documentdelete`;
    const response = await http.postAsFormData<ApiResponse<DocumentList[]>>(
      url,
      formData
    );
    return response.data?.list;
  }

  public async getUsersList(pageNo: number): Promise<UserResponse> {
    const url = `${this.apiDomain}/user/listusers`;
    const data = new FormData();
    data.append('page', pageNo?.toString());
    const response = await http.postAsFormData<UserApiResponse<any>>(url, data);
    const { result, totalUsers } = response?.data;

    return {
      result,
      totalUsers,
    };
  }

  public async createUsers(data: UsersList): Promise<any> {
    const url = `${this.apiDomain}/user/createuser`;
    const formData = new FormData();
    formData.append('userEmail', data.userEmail);
    formData.append('userPhone', data.userPhone);
    formData.append('userFirstName', data.userFirstName);
    formData.append('userLastName', data.userLastName);
    formData.append('orderPermission', data.orderPermission);
    formData.append('userCountryCode', data.userCountryCode);
    formData.append('userDesignation', data.userDesignation);
    formData.append('userPhoneDialCode', data.userPhoneDialCode);
    const response = await http.postAsFormData<ApiResponse<UsersList[]>>(
      url,
      formData
    );
    return response?.data;
  }

  public async updateUsers(data: UsersList): Promise<any> {
    const url = `${this.apiDomain}/user/edituser`;
    const formData = new FormData();
    formData.append('userEmail', data.userEmail);
    formData.append('userPhone', data.userPhone);
    formData.append('userFirstName', data.userFirstName);
    formData.append('userLastName', data.userLastName);
    formData.append('orderPermission', data.orderPermission);
    formData.append('userCountryCode', data.userCountryCode);
    formData.append('userDesignation', data.userDesignation);
    formData.append('userPhoneDialCode', data.userPhoneDialCode);
    formData.append('userID', data.userID);
    const response = await http.postAsFormData<ApiResponse<UsersList[]>>(
      url,
      formData
    );
    return response?.data;
  }

  public async deleteUser(id: string): Promise<any> {
    const url = `${this.apiDomain}/user/deleteUser`;
    const formData = new FormData();
    formData.append('userID', id);
    const response = await http.postAsFormData<ApiResponse<any>>(url, formData);
    return response?.data;
  }

  public async getShippingAddresses(): Promise<TermsResponse> {
    const url = `${this.apiDomain}/user/gettermsofuse`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<TermsResponse>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getCustomers(
    filterParams: ICustomerFilterParams,
    isDashboard: boolean
  ): Promise<ApiResponse<ICustomer[]>> {
    const url = `${this.apiDomain}/salesperson/ordercustomers/`;
    const data = new FormData();
    if (filterParams.page) {
      data.append('page', `${filterParams.page}`);
    }
    if (filterParams.perPage) {
      data.append('perPage', `${filterParams.perPage}`);
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (isDashboard) {
      data.append('dashboard', 'Yes');
    }
    if (filterParams.searchKeyword) {
      data.append('searchKeyword', filterParams?.searchKeyword ?? '');
    }
    if (filterParams.fromDate) {
      data.append('fromDate', filterParams.fromDate);
    }
    if (filterParams.toDate) {
      data.append('toDate', filterParams.toDate);
    }
    // Object.keys(filterParams.filter)?.forEach((p) => {
    //   const value = (filterParams.filter as any)[p]?.value;
    //   if (value) {
    //     if (p === FilterTypes.brand) {
    //       data.append('brandID', value);
    //     }
    //     if (p === FilterTypes.branch) {
    //       data.append('branchID', value);
    //     }
    //   }
    // });

    Object.keys(filterParams.filter)?.forEach((p) => {
      if (p === FilterTypes.brand) {
        const value = (filterParams.filter as any)[p]?.value;
        if (value) {
          data.append('brandID', value);
        }
      }
      if (p === FilterTypes.branch) {
        const val: string = (filterParams.filter as any)[p]
          ?.map((brnch) => brnch.value)
          ?.join(',');
        if (val.length > 0) {
          data.append('branchID', val);
        }
      }
    });

    const response = await http.postAsFormData<ApiResponse<ICustomer[]>>(
      url,
      data
    );
    return response.data;
  }

  public async getCustomerRequest(
    page: number,
    perPage?: number
  ): Promise<ApiResponse<ICustomer[]>> {
    const url = `${this.apiDomain}/salesperson/ordercustomers/`;
    const data = new FormData();
    data.append('page', `${page}`);
    data.append('perPage', `${perPage}`);
    const response = await http.postAsFormData<ApiResponse<ICustomer[]>>(
      url,
      data
    );
    return response.data;
  }

  public async getCustomersRequest(
    filterParams: ICustomerRequestFilterParams
  ): Promise<ApiResponse<CustomerRequestResponse>> {
    const url = `${this.apiDomain}/salesperson/customerList/`;
    const data = new FormData();
    data.append('page', `${filterParams.page}`);
    data.append('perPage', `${filterParams.perPage}`);
    data.append('brandID', '');
    data.append('branchID', '');
    if (filterParams.status) {
      data.append('status', filterParams.status);
    }
    if (filterParams.searchName) {
      data.append('searchName', filterParams?.searchName ?? '');
    }
    if (filterParams.fromDate) {
      data.append(
        'searchDate',
        `${filterParams.fromDate}/${filterParams.toDate ?? filterParams.fromDate
        }`
      );
    }

    Object.keys(filterParams.filter)?.forEach((p) => {
      const value = (filterParams.filter as any)[p]?.value;
      if (value) {
        if (p === FilterTypes.brand) {
          data.append('searchBrands', value);
        }
        if (p === FilterTypes.branch) {
          data.append('searchBranch', value);
        }
      }
    });

    const response = await http.postAsFormData<
      ApiResponse<CustomerRequestResponse>
    >(url, data);
    return response.data;
  }

  public async getDeclineReason(
    type: 'Decline' | 'Revoke'
  ): Promise<ApiResponse<IReason[]>> {
    const url = `${this.apiDomain}/salesperson/listReasons/`;
    const data = new FormData();
    data.append('type', type);
    data.append('userToken', '');
    const response = await http.postAsFormData<ApiResponse<IReason[]>>(
      url,
      data
    );
    return response.data;
  }

  public async declineCustomerRequest(
    param: DeclineRequestParam
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/salesperson/decline`;
    const data = new FormData();
    data.append('userID', param.userID);
    data.append('reasonID', param.reasonID);
    data.append('declineComment', param.declineComment);
    data.append('notifyCustomer', param.notifyCustomer);
    data.append('userToken', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response.data;
  }

  public async revokeCustomerRequest(
    param: DeclineRequestParam
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/salesperson/revoke`;
    const data = new FormData();
    data.append('userID', param.userID);
    data.append('reasonID', param.reasonID);
    data.append('revokeComments', param.declineComment);
    data.append('notifyCustomer', param.notifyCustomer);
    data.append('userToken', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response.data;
  }

  public async addBranchAndApprove(
    param: IAddApproveParam
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/salesperson/addBranchAndApprove`;
    const data = new FormData();
    data.append('userID', param.userID);
    data.append('brandBranch', JSON.stringify(param.brands));
    data.append('userToken', '');
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response.data;
  }

  public async getSalespersonAccountSettings(
    userToken?: string
  ): Promise<IPersonalInfo> {
    const url = `${this.apiDomain}/salesperson/accountSettings/`;
    const data = new FormData();
    // if (userToken) {
    //   data.append('userToken', userToken);
    // }
    data.append('action', 'view');

    const response = await http.postAsFormData<ApiResponse<IPersonalInfo>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async getUserLogs(
    params: IUserLogParams
  ): Promise<ApiResponse<IUserLog[]>> {
    const url = `${this.apiDomain}/user/listUserLogs/`;
    const data = new FormData();
    if (params.userToken) {
      data.append('userToken', params.userToken);
      data.append('salespersonToken', '');
    } else {
      data.append('userToken', '');
    }
    data.append('userID', params.userID);
    data.append('perPage', `${params.perPage}`);
    data.append('page', `${params.page}`);

    const response = await http.postAsFormData<ApiResponse<IUserLog[]>>(
      url,
      data
    );
    return response?.data;
  }
  public async editSalesPerson(data: any): Promise<any> {
    const url = `${this.apiDomain}/salesperson/accountSettings/`;
    const formData = new FormData();
    formData.append('action', 'update');
    formData.append('languagesKnown', data?.languagesKnown);
    formData.append('countryID', data?.userCountryCode);
    formData.append('roleTypeID', data?.roleTypeID);
    formData.append('rolesTypeName', data?.rolesTypeName);
    formData.append('stateID', data?.stateID);
    formData.append('stateName', data?.stateName);
    formData.append('userAdress1', data?.userAddress);
    formData.append('userCity', data?.userCity);
    formData.append('userDOB', data?.userDOB);
    formData.append('userEmail', data?.userEmail);
    formData.append('userFirstName', data?.userFirstName);
    formData.append('userLastName', data?.userLastName);
    formData.append('userGender', data?.userGender);
    formData.append('userZip', data?.userZip);
    formData.append('userProfilePicture', data?.userProfilePicture);
    formData.append('userPhone', data?.userPhone);
    formData.append('userDesignation', data?.userDesignation);
    formData.append('userDialcode', data?.userPhoneDialCode);
    formData.append('salespersonToken', data?.salespersonToken);

    const response = await http.postAsFormData<ApiResponse<UsersList[]>>(
      url,
      formData
    );
    return response?.data;
  } 

  public async getScheduleList(
    filterParams: IScheduleListParams
  ): Promise<ApiResponse<IScheduleResponse>> {
    const url = `${this.apiDomain}/schedule/schedulemasterlist/`;
    const data = new FormData();
    if (filterParams.searchKeyword) {
      data.append('searchKeyword', filterParams?.searchKeyword ?? '');
    }

    if (filterParams.brandID) {
      data.append('brandID', filterParams.brandID.toString());
    } else {
      data.append('brandID', '');
    }
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<IScheduleResponse>>(
      url,
      data
    );
    return response?.data;
  }

  public async getUnScheduledList(
    filterParams: IScheduleListParams
  ): Promise<ApiResponse<IScheduleCustomerListResponse>> {
    const url = `${this.apiDomain}/schedule/listCustomers/`;
    const data = new FormData();
    if (filterParams.searchKeyword) {
      data.append('searchKeyword', filterParams?.searchKeyword ?? '');
    }
    data.append('day', filterParams.day);
    data.append('page', filterParams?.searchKeyword ? '1' : filterParams.page);
    data.append('perPage', filterParams.perPage);
    if (filterParams.date) {
      data.append('date', filterParams.date);
    }
    if (filterParams?.brandID?.length > 0) {
      data.append('brandID', filterParams.brandID.toString());
    } else {
      data.append('brandID', '');
    }
    data.append('branchID', '');
    if (filterParams?.salespersonID?.length > 0) {
      data.append('salePersonId', JSON.stringify(filterParams.salespersonID));
    }
    const response = await http.postAsFormData<
      ApiResponse<IScheduleCustomerListResponse>
    >(url, data);
    return response?.data;
  }
  public async createSchedule(
    week: string,
    userIds: string[],
    day?: string
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/schedule/addschedule/`;
    const data = new FormData();

    data.append('day', week);
    data.append('userIDs', JSON.stringify(userIds));
    if (day) {
      data.append('date', day);
    }

    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getDayScheduleList(
    filterParams: IScheduleListParams
  ): Promise<ApiResponse<IDayScheduleItem[]>> {
    const url = `${this.apiDomain}/schedule/schedulelist/`;
    const data = new FormData();
    if (filterParams.searchKeyword) {
      data.append('searchKeyword', filterParams?.searchKeyword ?? '');
    }
    if (filterParams.brandID) {
      data.append('brandID', filterParams.brandID.toString());
    } else {
      data.append('brandID', '');
    }
    data.append('branchID', '');   
    if(filterParams?.fromWeb){
      data.append('fromWeb', filterParams?.fromWeb);
    }
    const response = await http.postAsFormData<ApiResponse<IDayScheduleItem[]>>(
      url,
      data
    );
    return response?.data;
  }

  public async getRequestScheduleList(
    params: any
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/schedule/schedulerequestlist/`;
    const data = new FormData(); 
    if (params?.status) data.append('status', params?.status);
    if (params?.searchKeyword) data.append('searchKeyword', params?.searchKeyword);
    if (params?.scheduleDetailID) data.append('scheduleDetailID', params?.scheduleDetailID);
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);       
    if (params?.action) data.append('action', params.action);    
    if (params?.reason) data.append('reason', params.reason);    
    if (params?.page) data.append('page', params.page);    
    if (params?.perPage) data.append('perPage', params.perPage); 
    if (params?.filter?.salesperson) data.append('salesperson', params?.filter?.salesperson?.map(item => item?.value).join(','));    
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);  
    return response.data;    
  }

  // customer-dashboard
  public async getCustomerDashboard(
    params: any
  ): Promise<ICustomerDashboard> {
    const url = `${this.apiDomain}/user/customerDashboard`;
    const data = new FormData();
    if (params?.type === 'graph') {
      data.append('graph', 'Yes');
    } else if (params?.type === 'tabular') {
      data.append('tabular', 'Yes');
    }
    if (params?.switchType === "Monthly") {
      data.append('Monthly', 'Yes');
    } else if (params?.switchType === "Quarterly") {
      data.append('Quarterly', 'Yes');
    } else if (params?.switchType === "Yearly") {
      data.append('Yearly', 'Yes');
    }
    data.append('userToken', params.userToken);
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('salespersonToken', params?.salespersonToken);

    if (params?.fromDate) data.append('fromDate', params.fromDate);

    const response = await http.postAsFormData<
      ApiResponse<ICustomerDashboard>
    >(url, data);

    return response?.data?.result;
  }

  // get userStatus
  public async getUserActiveStatus(): Promise<any> {
    const url = `${this.apiDomain}/user/getUserStatus`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;

  }
}
